import { MouseEvent, cloneElement, useEffect, useId, useState } from 'react';
import {
  Button,
  ButtonIcon,
  CardBaseFooter,
  CardBaseHeader,
  FlexContainer,
  Icon,
  IconWrapper,
  Label,
  Portal,
} from '@facephi/ui-react';
import { Transition } from 'react-spring';
import { ModalProps } from './model';
import { ModalStyled, SubmitButtonStyled } from './Styles';

export const CustomModal = ({
  className,
  children,
  overlay = true,
  overlayBlur = true,
  show = false,
  isForm,
  onChangeShow,
  onClickOutside,
  header,
  footer,
  hasPadding,
  testId = 'modal-wrapper',
}: ModalProps) => {
  const [innerShow, setInnerShow] = useState<boolean>(show);
  const idForm = useId();

  useEffect(() => {
    setInnerShow(show);
  }, [show]);

  useEffect(() => {
    onChangeShow && onChangeShow(innerShow);
  }, [innerShow]);

  const onCloseModal = () => {
    setInnerShow(false);
  };

  const handleCloseButton = (event: MouseEvent<HTMLButtonElement>) => {
    onCloseModal();
    footer?.onCloseButton?.(event);
  };

  const handleClickOutside = (event: MouseEvent<HTMLButtonElement>) => {
    onCloseModal();
    onClickOutside && onClickOutside(event);
  };

  const handleClickCloseIcon = (event: MouseEvent<HTMLButtonElement>) => {
    onCloseModal();
    footer?.onClickCloseIcon?.(event);
  };

  return (
    <Transition
      items={innerShow}
      from={{ opacity: 0, transform: 'translate(-50%, -50%) scale(0.5)' }}
      enter={{ opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }}
      leave={{ opacity: 0, transform: 'translate(-50%, -50%) scale(0.5)' }}
    >
      {(styles, innerShow) =>
        innerShow && (
          <Portal
            show={innerShow}
            onClickOutside={handleClickOutside}
            actionRef={null}
            overlayBlur={overlayBlur}
            overlay={overlay}
            testId="modal"
          >
            <ModalStyled
              style={{ ...styles }}
              data-test={testId}
              className={className}
            >
              {header && (
                <CardBaseHeader noBorder={header?.noBorder}>
                  <FlexContainer alignItems="center" columnGap="1.2">
                    {!header?.iconBackgroundColor && header?.iconName && (
                      <Icon
                        iconName={header?.iconName}
                        size="24"
                        color={header?.iconColor || 'grey600'}
                      />
                    )}
                    {header?.iconBackgroundColor && header?.iconName && (
                      <IconWrapper
                        size="M"
                        color={header?.iconBackgroundColor}
                        iconName={header?.iconName}
                      />
                    )}
                    {header?.title && (
                      <Label semibold fontSize="14">
                        {header.title}
                      </Label>
                    )}
                  </FlexContainer>
                  <ButtonIcon
                    variant="text"
                    onClick={handleClickCloseIcon}
                    iconName="X"
                    testId="card-close-button"
                  />
                </CardBaseHeader>
              )}

              {cloneElement(children, {
                id: isForm ? idForm : '',
                'data-test': 'modal-testing',
                style: { padding: hasPadding ? '2.4rem' : 0 },
              })}

              {footer && (
                <CardBaseFooter
                  columnGap="2"
                  noBorder={footer?.noBorder}
                  hasPadding
                >
                  {footer?.closeButtonLabel && (
                    <Button
                      testId="data-test-cancel-button"
                      variant="text"
                      onClick={handleCloseButton}
                    >
                      {footer?.closeButtonLabel}
                    </Button>
                  )}
                  {footer?.submitButtonLabel && (
                    <SubmitButtonStyled
                      onClick={footer?.onSubmitButton}
                      form={isForm ? idForm : ''}
                      type={isForm ? 'submit' : 'button'}
                      testId="data-test-submit-button"
                      css={{
                        width: footer?.closeButtonLabel ? '' : '100%',
                      }}
                    >
                      {footer?.submitButtonLabel}
                    </SubmitButtonStyled>
                  )}
                </CardBaseFooter>
              )}
            </ModalStyled>
          </Portal>
        )
      }
    </Transition>
  );
};
