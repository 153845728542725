import React from 'react';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { BrazilFlag, EnglandFlag, SpainFlag } from '../components/icons';

type IProps = {
  children: React.ReactNode;
};

export enum Languages {
  es = 'es',
  en = 'en',
  pt = 'pt',
}

export const languagesObject = {
  [Languages.es]: <SpainFlag />,
  [Languages.en]: <EnglandFlag />,
  [Languages.pt]: <BrazilFlag />,
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: (lng: string) => `/locales/${lng[0].split('-')[0]}.json`,
    },
    fallbackLng: Languages.en,
    interpolation: {
      escapeValue: false,
    },
  });

export const TranslationProvider = ({ children }: IProps) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
