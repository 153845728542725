import { useDeviceSize } from '@facephi/ui-react';
import { ModalSelection, SelectionLayout } from '../components';

const MethodSelection = () => {
  const { isDesktop } = useDeviceSize();

  return (
    <SelectionLayout
      padding
      flexDirection="column"
      alignItems="center"
      rowGap={isDesktop ? '3.2' : '0.8'}
    >
      <ModalSelection />
    </SelectionLayout>
  );
};

export default MethodSelection;
