import {
  InphiniteThemeColors,
  InphiniteThemeGradients,
} from '@facephi/ui-react';

type SelphiThemePorps = {
  colors: Partial<{ [key in InphiniteThemeColors]: string }>;
  gradients: Partial<{ [key in InphiniteThemeGradients]: string }>;
};

export const SelphiDemoTheme: SelphiThemePorps = {
  colors: {
    primary100: '#D5DAFA',
    primary200: '#95A3F2',
    primary300: '#556CEB',
    primary400: '#3167FC',
    primary500: '#2B50B6',
    primary600: '#151F57',
    primary700: '#141A3B',

    secondary100: '#E4D7FE',
    secondary200: '#BA9DF9',
    secondary300: '#976BF6',
    secondary400: '#3167FC',
    secondary500: '#2B50B6',
    secondary600: '#3A1D7A',
    secondary700: '#1D0E3D',

    tomato100: '#FCDADC',
    tomato200: '#FBA7AC',
    tomato300: '#FD7D7D',
    tomato400: '#FF625E',
    tomato500: '#CC4E4B',
    tomato600: '#602827',
    tomato700: '#33242A',

    purple100: '#DFDCF4',
    purple200: '#AFA7E2',
    purple300: '#8072D1',
    purple400: '#604FC6',
    purple500: '#4D3F9E',
    purple600: '#302863',
    purple700: '#221D41',

    cyan100: '#C0E6EF',
    cyan200: '#80CDDF',
    cyan300: '#40B4CF',
    cyan400: '#009ABF',
    cyan500: '#00748F',
    cyan600: '#004D60',
    cyan700: '#001E26',

    pink100: '#FDE1FF',
    pink200: '#F9B5FE',
    pink300: '#F589FE',
    pink400: '#F36BFE',
    pink500: '#C256CB',
    pink600: '#662B6A',
    pink700: '#311533',

    yellow100: '#FEF2DB',
    yellow200: '#FCDFA6',
    yellow300: '#FACD71',
    yellow400: '#F9C04D',
    yellow500: '#CDA044',
    yellow600: '#67542C',
    yellow700: '#322D29',

    green100: '#CCF4F0',
    green200: '#7FE3D9',
    green300: '#33D3C3',
    green400: '#00C8B4',
    green500: '#05A697',
    green600: '#0F6058',
    green700: '#192E33',

    blue100: '#D5DAFA',
    blue200: '#95A3F2',
    blue300: '#556CEB',
    blue400: '#3167FC',
    blue500: '#2B50B6',
    blue600: '#151F57',
    blue700: '#141A3B',

    skyBlue100: '#D1F2FE',
    skyBlue200: '#8DDEFC',
    skyBlue300: '#49CAFA',
    skyBlue400: '#1BBDF9',
    skyBlue500: '#1697C7',
    skyBlue600: '#0E5F7D',
    skyBlue700: '#052632',

    grey50: '#F3F4F5',
    grey100: '#EAE7F5',
    grey200: '#D3D9ED',
    grey300: '#A4ABC3',
    grey400: '#7C879A',
    grey500: '#5A6476',
    grey600: '#3A4151',
    grey700: '#2B303C',
    grey800: '#20222C',
    grey900: '#181922',

    white: '#FFFFFF',

    blackAndroid: '#000000',
    blackIos: '#1C1C1E',

    success100: '#D6F1DA',
    success200: '#99DDA1',
    success300: '#31D16C',
    success400: '#2CC13D',
    success500: '#239A31',
    success600: '#16611F',
    success700: '#15421B',

    warning100: '#FFE7D3',
    warning200: '#FFC291',
    warning300: '#FF9D4F',
    warning400: '#FF7F00',
    warning500: '#CC6600',
    warning600: '#804000',
    warning700: '#552E08',
  },
  gradients: {
    lightGreen: 'linear-gradient(to top, #54CDFF, #BAFF77)',
    peach: 'linear-gradient(to top, #CC52EA, #FF7D90)',
    pinkPurple: 'linear-gradient(to top, #8463D4, #E746E9)',
    bluePurple: 'linear-gradient(to top, #8839FD, #7189FC)',
    purple: 'linear-gradient(to top, #9A77E3, #5D3EA0)',
    blue: 'linear-gradient(to top, #494eed, #009ABF)',
  },
};
