import 'react-loading-skeleton/dist/skeleton.css';
import { setColorTheme } from '@facephi/ui-react';
import { createGlobalStyle } from 'styled-components';
import { desktopStyles, HEADER_HEIGHT, mobileStyles } from './utils';

export const GlobalStyles = createGlobalStyle`

@font-face {
    font-family: 'Poppins';
    src: url('/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }


  html {
    font-size: 62.5%;
    min-height: 100%;
    scroll-behavior: smooth;
    height: 100%;

  }

  body {
    line-height: 1;
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: auto;
  }

  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  a {
    text-decoration: none;
  }
  body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    background-color: #ffffff;
  }

  input, button {
    font-family: 'Poppins', sans-serif;
  }
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear
    {
        display: none;
    }

  strong {
    font-weight: 600;
  }

  * {
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 2px;
      box-shadow: none;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b3bdcd;
      border-radius: 8px;
      box-shadow: none;
    }
  }

  .selphid-widget:not(.full-screen_desktop) {
    ${desktopStyles} {
      min-width: 76rem !important;
      width: 100% !important;
      max-width: 90rem !important;
      height: 50rem !important;
    }
  }

  .selphi-widget {
    padding: 2rem;
    background: ${setColorTheme('white')};
    overflow: hidden;

    &:not(.full-screen_mobile) {
      ${mobileStyles} {
        width: 100% ;
        height: calc(100%  - ${HEADER_HEIGHT}) ;
        position: fixed;
        z-index:1 ;
        top: ${HEADER_HEIGHT};
        left: 0;
      }
    }

    &:not(.full-screen_desktop) {
      ${desktopStyles} {
        width: 80rem;
        height: 80rem;
        border-radius: 2.4rem;
        box-sizing: content-box;
      }
    }
  }

  .voice-widget {
    height: 100%;
    width: 100%;
    max-height: 80rem;
    max-width: 80rem;
    background-color: ${setColorTheme('white')};
    border-radius: 2.4rem;

    ${mobileStyles} {
      min-height: unset;
      border-radius: 0;
      maring: unset;
    }
  }

  .tip-widget {
    height: auto;
    width: 100%;
    align-items: center;
    ${mobileStyles} {
        justify-content: space-between;
    }

    ${desktopStyles} {
      width: 60rem;
      }
    
    .lottie-content {
      row-gap: 3.2rem;
      > * {
        border-radius: 0;
        }
    }

    .lottie-desktop {
      svg {
        border-radius: 1.6rem;
        background: ${setColorTheme('white')};
        overflow: hidden;
      }
      ${mobileStyles} {
        display: none;
      }
    }

    .lottie-mobile {
      ${desktopStyles} {
        display: none;
      }
    }

    p {
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: ${setColorTheme('grey700')};
      text-align: center;
    }

    button {
      color: ${setColorTheme('white')};
      background: ${setColorTheme('purple400')};
      height: 4.8rem;
      padding: 0 3.2rem;
      border-radius: 0.8rem;
      font-size: 1.6rem;
      line-height: 2.1rem;
      font-weight: 600;
      font-family: inherit;
      box-sizing: border-box;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 0.8rem;
      transition: all 0.25s ease-in-out;
      white-space: nowrap;
      position: relative;
      width: 100%;
      margin: 0 !important;
      text-transform: uppercase;

      &:disabled {
        background-color: ${setColorTheme('grey200')};
        cursor: default;
        color: ${setColorTheme('grey300')};
      }

      &:not(:disabled):hover {
        background-color: ${setColorTheme('purple500')};
      }
    }
  }
`;
