export type LoginDto = {
  username: string;
  password: string;
};

export enum TokenGrantType {
  Password = 'password',
  PasswordRealm = 'password_realm',
  RefreshToken = 'refresh_token',
  Revoke = 'revoke',
}

export const CLAIMS_TOKEN = 'http://facephi.com/identity/claims/';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ClaimsType = { [key in string]: any };

export enum ClaimsList {
  email = 'email',
  videoAssistance = 'enable_video',
  antifraud = 'antifraud',
  pad = 'pad',
  tenant = 'tenant',
}
