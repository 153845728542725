import { Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import facial from '../../assets/lottie/facial.json';
import transfer from '../../assets/lottie/transfer.json';
import validatingVoiceDesktop from '../../assets/lottie/validatingVoiceDesktop.json';
import validatingVoiceMobile from '../../assets/lottie/validatingVoiceMobile.json';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { useFacialAuth, useVocalAuth } from '../../providers';
import { widgetTypeState, widgetTypes } from '../../states';
import { LottieAnimation } from '../lottieAnimation';
import { ContainerValidating } from './Styles';

type ValidatingProps = {
  transferValue: boolean;
};

export const Validating = ({ transferValue }: ValidatingProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useDeviceSize();
  const widgetType = useRecoilValue(widgetTypeState);
  const { facialValidated } = useFacialAuth();
  const { vocalValidated } = useVocalAuth();
  const validatingVoice = isDesktop
    ? validatingVoiceDesktop
    : validatingVoiceMobile;

  const getContents = ():
    | { animation: Record<string, unknown>; text: string }
    | undefined => {
    const animation =
      widgetType === widgetTypes.facial ? facial : validatingVoice;
    return ((widgetType === widgetTypes.facial && facialValidated) ||
      vocalValidated) &&
      transferValue
      ? { animation: transfer, text: t('Making transfer') }
      : { animation, text: t('Validating your identity') };
  };

  return (
    <ContainerValidating
      flexDirection="column"
      flex="1"
      alignItems="center"
      rowGap={isDesktop ? '3' : '1.6'}
    >
      <LottieAnimation
        animation={getContents()?.animation}
        style={{ height: '25rem' }}
      />
      <Label fontSize="18" semibold textAlign="center">
        {getContents()?.text}
        ...
      </Label>
    </ContainerValidating>
  );
};
