export const WarningBlue = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '166'}
    height={height || '148'}
    viewBox="0 0 166 148"
    fill="none"
  >
    <path
      d="M69.9744 7.48685C75.7075 -2.49822 90.0425 -2.49822 95.7756 7.48685L129.477 66.1659L163.178 124.845C168.911 134.827 161.745 147.306 150.278 147.306H15.4737C4.00464 147.306 -3.15946 134.827 2.57162 124.843L36.273 66.1639L69.9744 7.48685Z"
      fill="#9CB5F9"
    />
    <path
      d="M157.823 127.949L90.4201 10.5953C89.6552 9.26344 88.5551 8.1575 87.2302 7.38857C85.9054 6.61965 84.4025 6.21484 82.8727 6.21484C81.3429 6.21484 79.8401 6.61965 78.5152 7.38857C77.1904 8.1575 76.0902 9.26344 75.3254 10.5953L7.92356 127.952C7.15905 129.284 6.75667 130.795 6.75684 132.332C6.757 133.87 7.15972 135.38 7.92452 136.712C8.68931 138.043 9.78925 139.149 11.1138 139.918C12.4384 140.687 13.9409 141.092 15.4705 141.092H150.277C151.807 141.092 153.309 140.687 154.634 139.918C155.958 139.149 157.058 138.043 157.823 136.712C158.588 135.38 158.99 133.87 158.99 132.332C158.99 130.794 158.588 129.284 157.823 127.952V127.95V127.949ZM16.6542 131.643L82.8742 16.3475L149.093 131.643H16.6542Z"
      fill="#243760"
    />
    <path
      d="M82.876 64.6252C88.3293 64.6252 92.7501 60.1812 92.7501 54.6993C92.7501 49.2174 88.3293 44.7734 82.876 44.7734C77.4227 44.7734 73.002 49.2174 73.002 54.6993C73.002 60.1812 77.4227 64.6252 82.876 64.6252Z"
      fill="#243760"
    />
    <path
      d="M84.0799 106.808C86.9315 106.14 88.7052 103.276 88.0416 100.409C87.378 97.5429 84.5285 95.7599 81.6769 96.4269C78.8253 97.094 77.0516 99.9585 77.7152 102.825C78.3788 105.692 81.2284 107.475 84.0799 106.808Z"
      fill="#243760"
    />
    <path
      d="M73.002 54.6992L77.674 102.651L88.1105 102.424L92.7501 54.6992H73.002Z"
      fill="#243760"
    />
    <path
      d="M83.8961 124.76C87.3842 124.198 89.7585 120.899 89.1992 117.393C88.6399 113.886 85.3587 111.5 81.8706 112.062C78.3824 112.624 76.0081 115.923 76.5675 119.429C77.1268 122.936 80.4079 125.322 83.8961 124.76Z"
      fill="#243760"
    />
  </svg>
);
