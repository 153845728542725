import { FlexContainer, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const MainStyles = styled(FlexContainer)<{
  $negative?: boolean;
  $padding?: boolean;
}>`
  padding: ${({ $padding }) => ($padding ? '1.6rem' : '0')};
  position: relative;

  ${({ $negative }) =>
    $negative
      ? `background-color: ${setColorTheme('purple500')};`
      : `background-color: ${setColorTheme('grey50')};`}

  ${desktopStyles} {
    padding: ${({ $padding }) => ($padding ? '2.4rem' : '0')};
  }
`;

export const WidgetWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${setColorTheme('grey50')};
  display: flex;
  justify-content: center;

  ${desktopStyles} {
    padding-bottom: 5.6rem;
    margin-top: 2.5rem;
  }
`;
