import { Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { WarningBlue } from '../icons';
import { ModalLogoutContent, ModalLogoutStyles } from './Styles';

type ModalLogoutProps = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  onLogout(): void;
};

export const ModalLogout = ({
  show,
  onChangeShow,
  onLogout,
}: ModalLogoutProps) => {
  const { t } = useTranslation();

  return (
    <ModalLogoutStyles
      show={show}
      onChangeShow={onChangeShow}
      header={{ noBorder: true }}
      footer={{
        onSubmitButton: () => onLogout(),
        onCloseButton: () => onChangeShow(false),
        closeButtonLabel: t('Cancel').toLocaleUpperCase() || '',
        submitButtonLabel: t('Logout').toLocaleUpperCase() || '',
      }}
      hasPadding
    >
      <ModalLogoutContent
        flexDirection="column"
        rowGap="3.2"
        alignItems="center"
      >
        <WarningBlue />
        <Label fontSize="14" textAlign="center">
          {t('Are you sure you want to logout?')}
        </Label>
      </ModalLogoutContent>
    </ModalLogoutStyles>
  );
};
