import { TypeFamily } from '@facephi/sdk-web';
import { InphiniteThemeProvider } from '@facephi/ui-react';
import { RecoilRoot } from 'recoil';
import { useVariables } from './hooks';
import {
  AuthProvider,
  FacialProvider,
  TransferProvider,
  TranslationProvider,
  ExtendedSdkProvider,
  GlobalProvider,
  VocalProvider,
} from './providers';
import { Routes } from './routes/Routes';
import { GlobalStyles, SelphiDemoTheme } from './styles';

export const App = () => {
  const {
    tenantId,
    trackingUrl,
    trackingUrlAssets,
    authTrackingUrl,
    clientId,
    clientSecret,
  } = useVariables();

  return (
    <InphiniteThemeProvider customTheme={SelphiDemoTheme}>
      <GlobalStyles />
      <GlobalProvider>
        <TranslationProvider>
          <AuthProvider>
            <RecoilRoot>
              <ExtendedSdkProvider
                trackingOptions={{
                  trackingUrl: trackingUrl as string,
                  trackingUrlAssets: trackingUrlAssets as string,
                  clientId: clientId as string,
                  authUrl: authTrackingUrl as string,
                  clientSecret: clientSecret as string,
                  tenantId: tenantId as string,
                  type: TypeFamily.authentication,
                }}
              >
                <FacialProvider>
                  <VocalProvider>
                    <TransferProvider>
                      <Routes />
                    </TransferProvider>
                  </VocalProvider>
                </FacialProvider>
              </ExtendedSdkProvider>
            </RecoilRoot>
          </AuthProvider>
        </TranslationProvider>
      </GlobalProvider>
    </InphiniteThemeProvider>
  );
};
