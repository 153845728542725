import { CSSProperties } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { getCssValue } from '../../styles';

type IProps = {
  style?: CSSProperties;
};
export const DonutChart = ({ style }: IProps) => {
  const { t } = useTranslation();

  const options = {
    tooltip: {
      trigger: 'item',
    },
    textStyle: {
      fontFamily: 'Poppins',
      color: getCssValue('grey200'),
      fontSize: 8,
      textBorderWidth: 0,
      textBorderDashOffset: 0,
    },
    itemStyle: {
      borderRadius: 16,
    },
    legend: {
      orient: 'vertical',
      align: 'left',
      left: 'left',
      top: '20',
      itemGap: 20,
      textStyle: {
        color: getCssValue('grey900'),
        fontFamily: 'Poppins',
        fontSize: 10,
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '40%'],
        center: ['65%', '30%'],
        labelLine: {
          show: false,
          length: 0,
        },
        label: {
          formatter: '${c}',
          fontSize: 12,
          padding: 12,
        },
        data: [
          {
            value: 750,
            name: t('Food'),
            itemStyle: {
              color: getCssValue('primary400'),
            },
          },
          {
            value: 375,
            name: t('Loans'),
            itemStyle: {
              color: getCssValue('purple200'),
            },
          },
          {
            value: 375,
            name: t('Car'),
            itemStyle: {
              color: getCssValue('green400'),
            },
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  return <ReactECharts option={options} style={style} />;
};
