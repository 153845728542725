import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const userNameState = atom<string>({
  key: 'userNameState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const userLastNameState = atom<string>({
  key: 'userLastnameState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const userInfoCompletedState = atom<boolean>({
  key: 'userInfoCompleted',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
