import { ReactNode } from 'react';

export enum VoiceValidationType {
  custom = 'custom',
  documentNumber = 'documentNumber',
  facephiBank = 'facephiBank',
  nameLastname = 'nameLastname',
}

export enum widgetTypes {
  facial = 'facial',
  vocal = 'vocal',
}

export const VoiceVerifList = {
  [VoiceValidationType.nameLastname]: {
    title: 'Your name and lastname',
    active: true,
  },
  [VoiceValidationType.documentNumber]: {
    title: 'Your id card number',
    active: true,
  },
  [VoiceValidationType.facephiBank]: {
    title: 'My bank is Facephi',
    active: true,
  },
  [VoiceValidationType.custom]: {
    title: 'Enter a custom sentence',
    active: true,
  },
};

export type Options = {
  title: string;
  hide?: boolean;
  element: {
    onlyChildren: boolean;
    message: string;
    subMessage?: string;
    onChange?(value: boolean): void;
    checked?: boolean;
    idSwitch?: string;
    children?: ReactNode;
    description?: string;
    borderTop?: boolean;
    padding?: string;
    hide?: boolean;
    separator?: string;
    disabled?: boolean;
  }[];
};
