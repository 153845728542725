export const getAssetFromBlob = async (assets: Blob[]): Promise<string[]> =>
  Promise.all(
    assets.map(
      async (asset: Blob) =>
        await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(asset);
          reader.onloadend = () => resolve(String(reader.result));
          reader.onerror = () => reject();
        })
    )
  );

export const formatBase64Asset = (assets: string[]) =>
  assets.map((asset: string) => asset.substring(asset.indexOf(',') + 1));
