import { useState } from 'react';
import {
  FlexContainer,
  Icon,
  Label,
  Button,
  useDeviceSize,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoutesName } from '../../states';
import { VoiceSelectionStyles, CloseButton } from './Styles';
import { VoiceTypesSelection } from './VoiceTypesSelection';

export const VoiceSelectionMenu = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceSize();
  const { state } = useLocation();

  const onContinue = () => {
    navigate(RoutesName.widget, {
      state: { ...(state as object), fromSelection: true },
    });
  };

  const onclose = () => {
    navigate(-1);
  };

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <VoiceSelectionStyles
      flexDirection="column"
      rowGap={isDesktop ? '3.2' : '2.4'}
    >
      <CloseButton onClick={onclose}>
        <Icon iconName="X" size="32" />
      </CloseButton>
      <Label fontSize={isDesktop ? '32' : '24'} semibold textAlign="center">
        {t('Voice recognition')}
      </Label>

      <FlexContainer flexDirection="column" rowGap="1.6">
        <Label fontSize="14" textAlign="center">
          {t(
            'To activate the voice recognition, choose the phrase to proceed with the registration.'
          )}
        </Label>
        <FlexContainer flexDirection="column" rowGap="0.8">
          <VoiceTypesSelection setDisableButton={setDisableButton} />
        </FlexContainer>
      </FlexContainer>
      <Button
        size="L"
        fullWidth
        color="primary"
        onClick={onContinue}
        disabled={disableButton}
      >
        {t('Start').toUpperCase()}
      </Button>
    </VoiceSelectionStyles>
  );
};
