import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  username: yup.string().required().email(),
  password: yup.string().required(),
});

export const transferSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
  iban: yup
    .string()
    .required('This field is required')
    .matches(
      /([a-zA-Z]{2})\s*\t*(\d{2})\s*\t*(\d{4})\s*\t*(\d{4})\s*\t*(\d{2})\s*\t*(\d{10})/,
      'Invalid IBAN, for example ES11 2222 3333 44 55555555555'
    ),
  amount: yup
    .number()
    .required('This field is required')
    .typeError('This field is required'),
});

export type UserInfoDto = {
  userName: string;
  userLastName: string;
};

export const userInfoSchema = yup.object().shape({
  userName: yup.string().required(),
  userLastName: yup.string().required(),
});
