import { FlexContainer, Modal } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const BehaviourAlertStyles = styled(Modal)`
  max-width: 36rem;

  div: nth-of-type(3) {
    border-top: 0;
  }
`;

export const BehaviourAlertContent = styled(FlexContainer)`
  padding: 0 3.2rem 3.2rem;
`;
