import { FlexContainer, setColorTheme, Label, Input } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { mobileStyles } from '../../styles';
export const VoiceSelectionStyles = styled(FlexContainer)`
  width: 100%;
  max-width: 38rem;
  margin-top: 10rem;

  ${mobileStyles} {
    max-width: 32rem;
  }
`;

export const CustomSentenceAdvice = styled(Label)`
  margin: 0.6rem;
`;

export const CustomSentenceInput = styled(Input)<{ error: boolean }>`
  > div > input {
    cursor: text;
    ${({ error }) => error && `border-color: ${setColorTheme('error400')}`}};
  }
`;

export const SelectionMenuWrapper = styled(FlexContainer)`
  margin: 1.6rem 0 2.4rem;
`;

export const CloseButton = styled(FlexContainer)`
  position: absolute;
  cursor: pointer;
  top: 3rem;
  right: 3rem;
`;
