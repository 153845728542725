import { FlexContainer, Label, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';
import { CustomModal } from '../customModal';

export const ConfigurationWrapper = styled(FlexContainer)`
  padding: 1.6rem;
  width: 100%;

  ${desktopStyles} {
    padding: 4rem 0;
    width: 80rem;
  }
`;

export const ConfigurationCard = styled.section`
  background-color: ${setColorTheme('white')};
  border-radius: 1.2rem;
  overflow: hidden;
`;

const HEIGHT = '4rem';
const HEIGHT_MOBILE = '5.6rem';

export const ConfigurationCardHeader = styled(Label)`
  background-color: ${setColorTheme('grey200')};
  padding: 0.8rem 1.6rem;
  min-height: ${HEIGHT};
`;

export const ConfigurationCardElement = styled(FlexContainer)<{
  $boxPadding: string;
  $borderTop?: boolean;
}>`
  padding: ${({ $boxPadding }) => $boxPadding};
  ${({ $borderTop }) =>
    $borderTop && `border-top: solid 0.1rem ${setColorTheme('grey100')}`};
  min-height: ${HEIGHT_MOBILE};
  ${desktopStyles} {
    min-height: ${HEIGHT};
  }
`;

export const ConfigurationCardWrapper = styled(FlexContainer)`
  padding: 0 1.6rem 1.6rem;
  width: 100%;
  max-width: 29.4rem;
  white-space: nowrap;
  overflow: hidden;

  ul {
    row-gap: 1.2rem;
  }

  li {
    align-items: center;
    min-height: 2.4rem;

    :last-of-type {
      margin-bottom: 1.2rem;
    }

    input[type='radio']:disabled + label {
      color: ${setColorTheme('grey300')};
    }
  }
`;

export const CustomPhraseInputWrapper = styled(FlexContainer)<{
  disabled: boolean;
}>`
  max-width: 26.4rem;
  padding: 0.8rem 0 1.6rem 1.6rem;

  > p {
    padding: 0.4rem 0 0 0.6rem;
    ${({ disabled }) => disabled && `color:${setColorTheme('grey300')}`};
  }
`;

export const UserDataInputsWrapper = styled(FlexContainer)`
  padding: 1.6rem;
  column-gap: 1.6rem;

  > div {
    width: 100%;
    max-width: 22.6rem;
  }
`;

export const ModalRegisterStyles = styled(CustomModal)`
  div: nth-of-type(3) {
    button: first-of-type {
      max-width: 10rem;
    }
  }
`;
