import { useState, useEffect } from 'react';
import {
  Button,
  FlexContainer,
  LogoAuthentication,
  useDeviceSize,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  useAuth,
  useFacialAuth,
  useGlobal,
  useVocalAuth,
} from '../../providers';
import {
  RoutesName,
  facialValidationState,
  userInfoCompletedState,
  userLastNameState,
  userNameState,
  voiceValidationState,
} from '../../states';
import { BehaviourAlert } from '../behaviourAlert';
import { ModalRegister as BioSelectModal } from '../configuration/ModalRegister';
import { ModalLogout } from '../logout';
import { LanguageSelection } from './LanguageSelection';
import { HeaderStyles } from './Styles';

export const Header = () => {
  const { logout, logged, terms, initError } = useAuth();
  const { t } = useTranslation();
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [showBioSelectModal, setShowBioSelectModal] = useState<boolean>(false);
  const userInfoCompleted = useRecoilValue(userInfoCompletedState);
  const userName = useRecoilValue(userNameState);
  const userLastName = useRecoilValue(userLastNameState);
  const hasFacialValidation = useRecoilValue(facialValidationState);
  const hasVocalValidation = useRecoilValue(voiceValidationState);
  const { isMobile } = useDeviceSize();
  const { showAlert } = useGlobal();
  const { facialValidated } = useFacialAuth();
  const { vocalValidated } = useVocalAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = !!userName && !!userLastName;
  const hasBiometry = hasFacialValidation || hasVocalValidation;

  const handleLogout = () => {
    userInfo && setShowLogoutModal(true);
  };

  useEffect(() => setShowAlertModal(showAlert), [showAlert]);
  const handleConfigClick = () => {
    location.pathname !== RoutesName.terms &&
      navigate(RoutesName.configuration);
  };

  const InnerLogo = () => (
    <LogoAuthentication height="3.2rem" width="10.7rem" />
  );

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (initError) {
      navigate(RoutesName.noAuthorized, { state: { initError } });
      timeoutId = setTimeout(() => logout(), 3000);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [initError]);

  return (
    <>
      {logged && terms && userInfoCompleted ? (
        <HeaderStyles
          forwardedAs="header"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link
            to={!userInfo || !hasBiometry ? '' : RoutesName.home}
            onClick={
              !hasBiometry ? () => setShowBioSelectModal(true) : undefined
            }
          >
            <InnerLogo />
          </Link>

          <FlexContainer
            alignItems="center"
            columnGap={!isMobile ? '1.6' : 'unset'}
          >
            {(facialValidated || vocalValidated) && (
              <Button
                color="white"
                variant="text"
                onClick={handleConfigClick}
                testId="go-configuration"
                iconName="Gear"
              >
                {!isMobile && t('Configuration')}
              </Button>
            )}
            <Button
              color="white"
              variant="text"
              onClick={handleLogout}
              testId="logout-button"
              iconName="SignOut"
            >
              {!isMobile && t('Exit')}
            </Button>
            <ModalLogout
              show={showLogoutModal}
              onChangeShow={(showModal) => setShowLogoutModal(showModal)}
              onLogout={logout}
            />
            <BehaviourAlert
              show={showAlertModal}
              onChangeShow={(showAlertBehaviour) =>
                setShowAlertModal(showAlertBehaviour)
              }
            />
            <BioSelectModal
              show={showBioSelectModal}
              onChangeShow={(showBioSelectModal) =>
                setShowBioSelectModal(showBioSelectModal)
              }
              onAddBiometric={() => setShowBioSelectModal(false)}
              onCancel={() => setShowBioSelectModal(false)}
              onClose={() => setShowBioSelectModal(false)}
            />
          </FlexContainer>
        </HeaderStyles>
      ) : (
        <LanguageSelection />
      )}
    </>
  );
};
