import { ChangeEvent, useEffect, useState } from 'react';
import { FlexContainer, Label, Switch, Input } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useFacialAuth, useVocalAuth } from '../../providers';
import { Options, RoutesName, widgetTypes } from '../../states';
import {
  behaviourState,
  facialValidationState,
  registrationState,
  userLastNameState,
  userNameState,
  voiceValidationState,
  widgetTypeState,
  antispoofingState,
} from '../../states/atoms';
import { GoBackHeader } from '../goBackHeader';
import { VoiceTypesSelection } from '../voiceSelection';
import { ModalRegister } from './ModalRegister';
import {
  ConfigurationCard,
  ConfigurationCardElement,
  ConfigurationCardHeader,
  ConfigurationCardWrapper,
  ConfigurationWrapper,
  UserDataInputsWrapper,
} from './Styles';

export const Configuration = () => {
  const { t } = useTranslation();
  const { facialValidated } = useFacialAuth();
  const { vocalValidated } = useVocalAuth();

  const navigate = useNavigate();

  const [behaviourTrack, setBehaviourTrack] = useRecoilState(behaviourState);
  const [facialValidation, setFacialValidation] = useRecoilState(
    facialValidationState
  );
  const [userName, setUserName] = useRecoilState(userNameState);
  const [userLastname, setUserLastName] = useRecoilState(userLastNameState);
  const [voiceValidation, setVoiceValidation] =
    useRecoilState(voiceValidationState);
  const [widgetType, setWdigetType] = useRecoilState(widgetTypeState);
  const [registration, setRegistration] = useRecoilState(registrationState);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [userInfoError, setUserInfoError] = useState({
    userName: '',
    userLastName: '',
  });
  const [antispoofing, setAntispoofing] = useRecoilState(antispoofingState);

  const noUserInfo = !!userInfoError.userName || !!userInfoError.userLastName;
  const noBiometry = !facialValidation && !voiceValidation;
  const goBackRoutes = noUserInfo || noBiometry ? '' :  RoutesName.home;

  useEffect(() => {
    if (noBiometry) {
      showSelectBioModal();
    }
  }, [facialValidation, voiceValidation]);

  useEffect(() => {
    if (registration) {
      setFacialValidation(facialValidated);
      setVoiceValidation(vocalValidated);
    }
  }, []);

  const showSelectBioModal = () => {
    setWdigetType(undefined);
    setShowModal(true);
  };
  const onChangeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'userName') {
      setUserName(event.target.value);
    } else {
      setUserLastName(event.target.value);
    };

    setUserInfoError((prevState) => {
        return {
          ...prevState,
          [event.target.name]: checkUserInfo(event.target.value) && `${event.target.name} is a required field`,
        };
      });
  };

  const onAddBiometric = () => {
    setShowModal(false);
    setRegistration(true);
    if (widgetType === widgetTypes.facial) {
      navigate(RoutesName.widget);
    } else {
      navigate(RoutesName.voiceSelection);
    }
  };

  const onCancelBiometric = () => {
    setShowModal(false);
    if (widgetType === widgetTypes.facial) {
      onChangeWidget(false, widgetTypes.facial);
    } else {
      onChangeWidget(false, widgetTypes.vocal);
    }
  };

  const onChangeWidget = (value: boolean, origin: widgetTypes) => {
    const validationMap = {
      [widgetTypes.facial]: {
        setValidation: setFacialValidation,
        validated: facialValidated,
      },
      [widgetTypes.vocal]: {
        setValidation: setVoiceValidation,
        validated: vocalValidated,
      },
    };

    const widgetInfo = validationMap[origin];
    const { setValidation, validated } = widgetInfo;

    setValidation(value);

    if (value && !validated) {
      setWdigetType(origin);
      setShowModal(true);
    }
  };

  const checkUserInfo = (inputValue: string): boolean => !inputValue;

  const configurationOptions: Options[] = [
    {
      title: 'User name',
      element: [
        {
          onlyChildren: true,
          message: '',
          children: (
            <UserDataInputsWrapper>
              <Input
                type="text"
                label={String(t('Name'))}
                name="userName"
                value={userName}
                onChange={onChangeInputHandler}
                errorLabel={
                  userInfoError.userName &&
                  (t(userInfoError.userName) as string)
                }
              />
              <Input
                type="text"
                label={String(t('Last name'))}
                name="userLastName"
                value={userLastname}
                onChange={onChangeInputHandler}
                errorLabel={
                  userInfoError.userLastName &&
                  (t(userInfoError.userLastName) as string)
                }
              />
            </UserDataInputsWrapper>
          ),
        },
      ],
    },
    {
      title: 'Facial Validation',
      element: [
        {
          onlyChildren: false,
          message: 'Face',
          onChange: (value: boolean) => {
            onChangeWidget(value, widgetTypes.facial);
          },
          checked: facialValidation,
          idSwitch: 'Facial',
          disabled: noUserInfo,
        },
      ],
    },
    {
      title: 'Voice Validation',
      element: [
        {
          onlyChildren: false,
          padding: '1.6rem 1.6rem 0.8rem 1.6rem',
          message: 'Voice',
          onChange: (value: boolean) => {
            onChangeWidget(value, widgetTypes.vocal);
          },
          checked: voiceValidation,
          idSwitch: 'Voice',
          disabled: noUserInfo,
          children: (
            <ConfigurationCardWrapper flexDirection="column">
              <VoiceTypesSelection />
            </ConfigurationCardWrapper>
          ),
        },
      ],
    },
    {
      title: 'Antispoofing',
      element: [
        {
          onlyChildren: false,
          borderTop: true,
          message: 'Antispoof validation',
          onChange: (value: boolean) => {
            setAntispoofing(value);
          },
          checked: antispoofing,
          idSwitch: 'Antispoof',
          description: 'Check antispoof in the process.',
        },
      ],
    },
    {
      title: 'Behaviour validation',
      hide: true,
      element: [
        {
          onlyChildren: false,
          message: 'Behaviour',
          description: 'It will be effective from the 5th transfer',
          onChange: setBehaviourTrack,
          checked: behaviourTrack,
          idSwitch: 'Behaviour',
        },
      ],
    },
  ];

  return (
    <>
      <ConfigurationWrapper flexDirection="column" rowGap="3.2">
        <GoBackHeader
          route={goBackRoutes}
          linkLabel="Go back"
          title="Configuration"
          onClick={!goBackRoutes ? () => setShowModal(true) : undefined}
        />
        {configurationOptions
          .filter(({ hide }) => !hide)
          .map((item, index) => (
            <ConfigurationCard as="ul" key={index}>
              <ConfigurationCardHeader forwardedAs="h2" fontSize="16" semibold>
                {t(item.title)}
              </ConfigurationCardHeader>

              {item.element
                ?.filter(({ hide }) => !hide)
                .map((value, index) => (
                  <>
                    {!value.onlyChildren && (
                      <ConfigurationCardElement
                        key={index}
                        alignItems="center"
                        justifyContent="space-between"
                        forwardedAs="li"
                        $boxPadding={value.padding || '1.6rem'}
                        $borderTop={value.borderTop}
                      >
                        <FlexContainer flexDirection="column" rowGap="0.6">
                          <Label fontSize="14" semibold>
                            {value.message && t(value.message)}
                            {value?.separator}
                            {value.subMessage && ` ${value.subMessage}`}
                          </Label>

                          {value.description && (
                            <Label fontSize="12" color="grey500">
                              {t(value.description)}
                            </Label>
                          )}
                        </FlexContainer>

                        {value.onChange && (
                          <Switch
                            id={value.idSwitch as string}
                            onChange={value.onChange}
                            checked={value.checked}
                            disabled={value.disabled}
                          />
                        )}
                      </ConfigurationCardElement>
                    )}
                    {value.children}
                  </>
                ))}
            </ConfigurationCard>
          ))}
        <ModalRegister
          show={showModal}
          onChangeShow={(showModal) => setShowModal(showModal)}
          onAddBiometric={onAddBiometric}
          onCancel={onCancelBiometric}
          methodType={widgetType}
          onClose={() => setShowModal(false)}
        />
      </ConfigurationWrapper>
    </>
  );
};
