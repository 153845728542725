import { Label, FlexContainer } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { widgetTypes } from '../../states';
import { ModalRegisterStyles } from './Styles';

type ModalRegisterProps = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  onAddBiometric(): void;
  onCancel(): void;
  methodType?: widgetTypes;
  onClose: () => void;
};

export const ModalRegister = ({
  show,
  onChangeShow,
  onAddBiometric,
  methodType,
  onClose,
  onCancel,
}: ModalRegisterProps) => {
  const { t } = useTranslation();

  const capitalizedMethod =
    t(methodType || '')
      .charAt(0)
      .toLocaleUpperCase() + t(methodType || '').slice(1);

  const modalContents = {
    title: methodType
      ? t('Add method as a security biometric?', {
          widgetType: capitalizedMethod,
        })
      : t('Select a biometric'),
    body: methodType
      ? t('You will be able to verify your identity through your method', {
          widgetType: t(methodType || ''),
        })
      : t('You must activate at least one biometric to continue'),
    onSubmitAction: methodType ? onAddBiometric : onClose,
    onCancelAction: methodType ? onCancel : undefined,
    onClickIconClose: methodType ? onCancel : undefined,
    onClickOutside: methodType ? onCancel : undefined,
    submitLabel: methodType
      ? t('Add').toLocaleUpperCase()
      : t('Continue').toLocaleUpperCase(),
    cancelLabel: methodType ? t('Cancel').toLocaleUpperCase() : undefined,
  };

  const {
    title,
    body,
    onSubmitAction,
    onCancelAction,
    onClickOutside,
    onClickIconClose,
    submitLabel,
    cancelLabel,
  } = modalContents;

  return (
    <ModalRegisterStyles
      show={show}
      onChangeShow={onChangeShow}
      onClickOutside={onClickOutside}
      header={{
        title: title as string,
      }}
      footer={{
        onSubmitButton: onSubmitAction,
        onCloseButton: onCancelAction,
        submitButtonLabel: submitLabel,
        closeButtonLabel: cancelLabel,
        onClickCloseIcon: onClickIconClose,
      }}
      hasPadding
    >
      <FlexContainer flexDirection="column" rowGap="3.2" alignItems="center">
        <Label fontSize="14" textAlign="center">
          {body}
        </Label>
      </FlexContainer>
    </ModalRegisterStyles>
  );
};
