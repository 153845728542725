import { FlexContainer, IconWrapper } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles, mobileStyles } from '../../styles';
import { Layout } from '../layout/Layout';

export const SelectionButtonsContainer = styled(FlexContainer)`
  padding: 1.6rem;
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
`;

export const SelectionLayout = styled(Layout)`
  overflow-y: auto;

  ${desktopStyles} {
    padding: 3rem 22.2vw 7.6rem;
  }
`;

export const SelectionStyles = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  max-width: 80rem;
  max-height: 80rem;
  flex: 1;
  border-radius: 2.4rem;
  overflow-y: auto;
`;

export const IconBox = styled(IconWrapper)`
  width: 6rem;
  height: 6rem;

  ${mobileStyles} {
    width: 5rem;
    height: 5rem;
  }
`;
