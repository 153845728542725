/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from 'react';
import { SdkProvider, TypeFamily } from '@facephi/sdk-web';
import { useAuth } from '../providers';

export type TrackingOptions = {
  trackingUrl: string;
  trackingUrlAssets: string;
  clientId: string;
  authUrl: string;
  clientSecret: string;
  tenantId: string;
  type: TypeFamily;
  disabled?: boolean;
};

type IProps = {
  children?: React.ReactNode;
  trackingOptions?: TrackingOptions;
  customerId?: string;
};

type ContextProps = {
  helpVideoAssistance: (id: string) => void;
};

const Ctx = createContext<ContextProps>({
  helpVideoAssistance: () => {},
});

export const ExtendedSdkProvider = ({
  children,
  trackingOptions = { disabled: true } as TrackingOptions,
}: IProps) => {
  const { email, tenantDefault } = useAuth();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((key) => key + 1);
  }, [tenantDefault]);

  const options = {
    ...trackingOptions,
    tenantId: (tenantDefault || trackingOptions.tenantId) as string,
  };

  return (
    <SdkProvider customerId={email} trackingOptions={{ ...options }}>
      {children}
    </SdkProvider>
  );
};

export const useExtendedSdk = () => useContext(Ctx);
