import {
  FlexContainer,
  Modal,
  setColorTheme,
  setSizeLabel,
  setShadowTheme,
  Button,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';
import { Layout } from '../layout/Layout';

export const ButtonsContainer = styled(FlexContainer)`
  padding: 1.6rem;
  border-top: 0.1rem solid ${setColorTheme('grey100')};
`;

export const TermsLayout = styled(Layout)`
  overflow-y: auto;

  > p {
    max-width: 19rem;
    ${desktopStyles} {
      ${setSizeLabel('32')};
      max-width: 90rem;
    }
  }
`;

export const ModalTermsStyles = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  max-width: 80rem;
  max-height: 80rem;
  background-color: ${setColorTheme('white')};
  flex: 1;
  border-radius: 2.4rem;
  overflow-y: auto;
  box-shadow: ${setShadowTheme('big')};
`;

export const ModalTermsContent = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  margin-top: 1.5rem;
  padding: 1.6rem;

  ${desktopStyles} {
    padding: 3.2rem 1.8rem 3.2rem 3.2rem;
  }

  h4,
  h5 {
    ${setSizeLabel('14')};
    font-weight: 600;
    padding: 0.4rem 0;

    ${desktopStyles} {
      ${setSizeLabel('18')};
      padding-bottom: 0.8rem;
    }
  }
  p,
  li {
    ${setSizeLabel('12')};

    ${desktopStyles} {
      ${setSizeLabel('14')};
    }
  }
  p,
  ul {
    padding-bottom: 1.2rem;
  }
  ul {
    padding: 0.4rem 0 0.4rem 1.6rem;
    list-style: disc;
  }
`;

export const ModalDeclineStyles = styled(Modal)`
  max-width: 36rem;

  div: nth-of-type(3) {
    button: first-of-type {
      color: ${setColorTheme('primary400')};
    }
  }
`;

export const ModalDeclineContent = styled(FlexContainer)`
  padding: 0 3.2rem 3.2rem;
`;

export const ButtonLink = styled(Button)`
  padding: 0;
`;
