import { useDeviceSize } from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useDeviceSize as useDeviceDimensions } from '../../hooks';
import { useAuth, useFacialAuth, useVocalAuth } from '../../providers';
import {
  UserInfoDto,
  userInfoSchema,
  RoutesName,
  userNameState,
  userLastNameState,
  userInfoCompletedState,
} from '../../states';
import { GoBackButton } from './GoBackButton';
import {
  Card,
  CardContainer,
  FrontFace,
  ButtonLogin,
  InputLogin,
  LoginCard,
  LoginForm,
  LogoLogin,
  LoginMessage,
} from './Styles';

export const UserInfoCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { terms, logout } = useAuth();
  const { isMobile } = useDeviceSize();
  const { deviceHeight } = useDeviceDimensions();
  const { facialValidated } = useFacialAuth();
  const { vocalValidated } = useVocalAuth();
  const isSmallScreen = deviceHeight <= 705;
  const setUserName = useSetRecoilState(userNameState);
  const setUserLastName = useSetRecoilState(userLastNameState);
  const [userInfoCompleted, setUserInfoCompleted] = useRecoilState(
    userInfoCompletedState
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userInfoSchema),
  });

  const onSubmit: SubmitHandler<UserInfoDto> = async (data: UserInfoDto) => {
    setUserName(data.userName);
    setUserLastName(data.userLastName);
    setUserInfoCompleted(true);
    navigate(
      terms
        ? facialValidated || vocalValidated
          ? RoutesName.home
          : RoutesName.widget
        : RoutesName.terms
    );
  };

  return (
    <>
      {userInfoCompleted ? (
        <Navigate
          to={
            terms
              ? facialValidated || vocalValidated
                ? RoutesName.home
                : RoutesName.widget
              : RoutesName.terms
          }
        />
      ) : (
        <CardContainer>
          <Card $smallScreen={isSmallScreen}>
            <FrontFace>
              {!isMobile && (
                <GoBackButton
                  iconSize="24"
                  iconName="CaretLeft"
                  iconColor="blue400"
                  labelText={'Go back'}
                  onClick={logout}
                  testId="goBack-logout"
                />
              )}
              <LoginCard>
                <LogoLogin width="19rem" height="5.6rem" black />
                <LoginForm
                  forwardedAs="form"
                  flexDirection="column"
                  justifyContent="center"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <LoginMessage textAlign="center" size={14} color="grey500">
                    {t('We want to offer you a personalised experience')}
                  </LoginMessage>

                  <LoginMessage
                    textAlign="left"
                    size={16}
                    color="grey700"
                    semibold
                  >
                    {t('Enter your name and surname')}
                  </LoginMessage>
                  <InputLogin
                    testId="user-info-name"
                    type="text"
                    label={t('Name') as string}
                    {...register('userName')}
                    placeholder={t('Name') as string}
                    errorLabel={errors.userName && t(errors.userName.message)}
                  />
                  <InputLogin
                    testId="user-info-lastName"
                    type="text"
                    {...register('userLastName')}
                    label={t('Last name') as string}
                    placeholder={t('Last name') as string}
                    errorLabel={
                      errors.userLastName && t(errors.userLastName.message)
                    }
                  />

                  <ButtonLogin
                    size="L"
                    type="submit"
                    color="primary"
                    testId="userInfo-continue-bttn"
                  >
                    {t('Continue').toLocaleUpperCase()}
                  </ButtonLogin>
                </LoginForm>
              </LoginCard>
            </FrontFace>
          </Card>
        </CardContainer>
      )}
    </>
  );
};
