/* eslint-disable @typescript-eslint/no-explicit-any */
export function useVariables() {
  const authUrl: string | undefined = (window as any)._env_
    .REACT_APP_AUTH_API_URL;
  const appApiId: string | undefined = (window as any)._env_
    .REACT_APP_AUTH_APPLICATION_ID;
  const apiUrl: string | undefined = (window as any)._env_.REACT_APP_API_URL;
  const appScope: string | undefined = (window as any)._env_.REACT_APP_SCOPE;
  const appScopeVersion: string | undefined = (window as any)._env_
    .REACT_APP_APP_SCOPE_VERSION;
  const apiClients: string | undefined = (window as any)._env_
    .REACT_APP_API_CLIENTS;
  const tenantId: string | undefined = (window as any)._env_
    .REACT_APP_TENANT_ID;
  const apiDemoUrl: string | undefined = (window as any)._env_
    .REACT_APP_API_DEMO_URL;
  const trackingUrl: string | undefined = (window as any)._env_
    .REACT_APP_API_TRACKING;
  const trackingUrlAssets: string | undefined = (window as any)._env_
    .REACT_APP_API_ASSETS;
  const clientId: string | undefined = (window as any)._env_
    .REACT_APP_CLIENTID_TRACKING;
  const clientSecret: string | undefined = (window as any)._env_
    .REACT_APP_CLIENTSECRET_TRACKING;
  const authTrackingUrl: string | undefined = (window as any)._env_
    .REACT_APP_AUTH_TRACKING_URL;
  const voiceWidgetLicense: string | undefined = (window as any)._env_
    .REACT_APP_VOICE_LICENSE;

  return {
    apiUrl,
    authUrl,
    appApiId,
    appScope,
    appScopeVersion,
    apiClients,
    tenantId,
    apiDemoUrl,
    trackingUrl,
    trackingUrlAssets,
    clientId,
    clientSecret,
    authTrackingUrl,
    voiceWidgetLicense,
  };
}
