import { FlexContainer, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const ContainerValidating = styled(FlexContainer)`
  padding: 3rem 2.4rem;
  width: 100%;
  background: ${setColorTheme('grey50')};

  ${desktopStyles} {
    padding: 5.6rem;
  }

  p {
    margin-top: 1.8rem;
  }
`;
