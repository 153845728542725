import { Button, FlexContainer, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const ContainerHelp = styled(FlexContainer)`
  background: ${setColorTheme('grey50')};
  padding: 3rem 2.4rem;
  width: 100%;
  max-width: 32rem;

  ${desktopStyles} {
    padding: 10rem 0;
  }

  p:nth-of-type(even) {
    white-space: pre-line;
  }
`;

export const ContainerHelpButton = styled(Button)`
  width: 32rem;
  ${desktopStyles} {
    width: 37rem;
  }
`;
