import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  Dispatch,
} from 'react';
import axios, { AxiosResponse } from 'axios';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { RequestMethods, useLogger } from '../hooks';
import { useVariables } from '../hooks/useVariables';
import { AuthName, endPoints, termsLocalStorage } from '../states/constants';
import {
  LoginDto,
  TokenGrantType,
  ClaimsType,
  CLAIMS_TOKEN,
  ClaimsList,
} from '../states/model/auth';
import { useFacialAuth } from './FacialProvider';
import { useGlobal } from './GlobalProvider';
import { useVocalAuth } from './VocalProvider';

const VOID_FUNCTION = () => undefined;

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  logged?: boolean;
  logout(): void;
  terms?: boolean;
  setTerms: Dispatch<boolean>;
  handleLogin(user: LoginDto): void;
  acceptTerms(): void;
  handleRefreshToken(): Promise<boolean>;
  error: string | null;
  token: string | null;
  tokenValid: boolean;
  tenants: string[];
  email?: string;
  processStarted: boolean;
  setProcessStarted: Dispatch<boolean>;
  setStartTracking: Dispatch<boolean>;
  startTracking: boolean;
  tenantDefault?: string;
  demoVersion: string;
  initError: boolean;
};

const Ctx = createContext<ContextProps>({
  logged: false,
  logout: () => {},
  terms: false,
  acceptTerms: () => {},
  handleLogin: () => {},
  handleRefreshToken: () => new Promise((resolve) => resolve(true)),
  error: null,
  token: null,
  tokenValid: false,
  tenants: [],
  processStarted: false,
  setProcessStarted: VOID_FUNCTION,
  setTerms: VOID_FUNCTION,
  setStartTracking: VOID_FUNCTION,
  startTracking: false,
  demoVersion: '',
  initError: false,
});

export const AuthProvider = ({ children }: IProps) => {
  const { authUrl, appApiId, apiDemoUrl } = useVariables();
  const { onRemoveFacial } = useFacialAuth();
  const { resetGlobalInfo, setUser, closeSession } = useGlobal();
  const { onRemoveRcord } = useVocalAuth();

  const [tenants, setTenants] = useState<string[]>([]);
  const [email, setEmail] = useState<string>();

  const [terms, setTerms] = useState<boolean>(
    localStorage.getItem(termsLocalStorage) ? true : false
  );

  const [logged, setLogged] = useState<boolean>(
    localStorage.getItem(AuthName.token) ? true : false
  );

  const [token, setToken] = useState<string | null>(
    localStorage.getItem(AuthName.token)
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem(AuthName.refreshToken)
  );

  const [error, setError] = useState<string | null>(null);

  const [processStarted, setProcessStarted] = useState<boolean>(false);
  const [tenantDefault, setTenantDefault] = useState<string>();
  const [startTracking, setStartTracking] = useState<boolean>(false);

  const {
    captureException,
    captureMessage,
    setUser: setUserSentry,
  } = useLogger();
  const [demoVersion, setDemoVersion] = useState<string>('');
  const [initError, setInitError] = useState<boolean>(false);
  const fetchingDemoVersion = useRef<boolean>(false);

  const isLogged = (token: string, refreshToken: string) => {
    localStorage.setItem(AuthName.token, token);
    localStorage.setItem(AuthName.refreshToken, refreshToken);
    setLogged(true);
    resetGlobalInfo();
  };

  const logout = () => {
    localStorage.removeItem(AuthName.token);
    localStorage.removeItem(AuthName.refreshToken);
    onRemoveFacial();
    onRemoveRcord();
    setLogged(false);
    resetGlobalInfo();
    setTerms(false);
    setTenantDefault(undefined);
  };

  useEffect(() => {
    if (closeSession) {
      captureMessage('Logout due to behaviour alert');
      logout();
    }
  }, [closeSession]);

  const tokenValid = useMemo(() => {
    if (token) {
      const decodeToken: JwtPayload | null = jwt.decode(token, {
        complete: true,
      });
      const dateNow = new Date();
      return decodeToken?.payload?.exp * 1000 > dateNow.getTime();
    }

    return false;
  }, [token]);

  const getClaims = (tokenInfo: JwtPayload) =>
    Object.entries(tokenInfo).reduce((acc, [key, value]) => {
      if (key.includes(CLAIMS_TOKEN)) {
        acc[key.replace(CLAIMS_TOKEN, '')] = value;
      }
      return acc;
    }, {} as ClaimsType);

  useEffect(() => {
    if (token) {
      const tokenInfo: JwtPayload = jwt.decode(token) as JwtPayload;
      setEmail(tokenInfo['http://facephi.com/identity/claims/email']);
      setTenants(tokenInfo['http://facephi.com/identity/claims/tenant']);
      setUser(tokenInfo['http://facephi.com/identity/claims/email']);
      const claims = getClaims(tokenInfo);

      if (claims.tenant) {
        if (claims[ClaimsList.tenant] instanceof Array) {
          setTenantDefault(claims[ClaimsList.tenant][0] as string);
        }
      }
      setUserSentry({
        email: tokenInfo['http://facephi.com/identity/claims/email'],
      });
    }
  }, [token]);

  useEffect(() => {
    if (!demoVersion && logged && !fetchingDemoVersion.current) {
      fetchingDemoVersion.current = true;
      axios({
        method: RequestMethods.get,
        url: `${apiDemoUrl}${endPoints.Versions}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AuthName.token)}`,
        },
      })
        .then((response) => {
          const { latest } = response.data;
          setDemoVersion(latest);
          fetchingDemoVersion.current = false;
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            handleRefreshToken()
              .then(() => {
                const config = error.config;
                config.headers[
                  'Authorization'
                ] = `Bearer ${localStorage.getItem(AuthName.token)}`;
                axios.request(config).then((response) => {
                  const { latest } = response.data;
                  setDemoVersion(latest);
                  fetchingDemoVersion.current = false;
                });
              })
              .catch(() => {
                fetchingDemoVersion.current = false;
                setInitError(true);
              });
          } else {
            fetchingDemoVersion.current = false;
            setInitError(true);
            throw new Error(error);
          }
        });
    }
    return () => setInitError(false);
  }, [logged]);

  const handleLogin = async (user: LoginDto) => {
    try {
      const response: AxiosResponse<
        { access_token: string; refresh_token: string },
        string
      > = await axios.post(`${authUrl}/auth/token`, {
        application_api_id: appApiId,
        grant_type: TokenGrantType.PasswordRealm,
        ...user,
      });

      if (response) {
        const {
          data: { access_token, refresh_token },
        } = response;
        setToken(access_token);
        setRefreshToken(refresh_token);
        setUser(user.username);
        isLogged(access_token, refresh_token);
      }
    } catch (error) {
      captureException(error as Error, { operation: 'handle Login' });
      if (axios.isAxiosError(error)) {
        setError(error.response?.data.title);
      }
    }
  };

  const acceptTerms = () => {
    setTerms(true);
    localStorage.setItem(termsLocalStorage, 'accepted');
  };

  const handleRefreshToken = (): Promise<boolean> =>
    new Promise((resolve, reject) => {
      axios
        .post(`${authUrl}/auth/token`, {
          application_api_id: appApiId,
          grant_type: TokenGrantType.RefreshToken,
          refresh_token: refreshToken,
        })
        .then((response) => {
          setToken(response.data.access_token);
          setRefreshToken(response.data.refresh_token);

          isLogged(response.data.access_token, response.data.refresh_token);
          resolve(true);
        })
        .catch(() => reject(false));
    });

  return (
    <Ctx.Provider
      value={{
        logged,
        logout,
        handleLogin,
        terms,
        acceptTerms,
        error,
        handleRefreshToken,
        token,
        tokenValid,
        tenants,
        email,
        processStarted,
        setProcessStarted,
        setTerms,
        tenantDefault,
        demoVersion,
        initError,
        startTracking,
        setStartTracking,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useAuth = () => useContext(Ctx);
