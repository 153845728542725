import { lazy, Suspense } from 'react';
import { Spinner } from '@facephi/ui-react';
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import MethodSelection from '../pages/MethodSelection';
import { RoutesName } from '../states';
import { ProtectedRoute } from './ProtectedRoute';

const Login = lazy(() => import('../pages/Login'));
const Terms = lazy(() => import('../pages/Terms'));
const Home = lazy(() => import('../pages/Home'));
const SuccessfulRegister = lazy(() => import('../pages/SuccessfulRegister'));
const DeniedRegister = lazy(() => import('../pages/DeniedRegister'));
const Transfers = lazy(() => import('../pages/Transfers'));
const TransfersProgress = lazy(() => import('../pages/TransferInProgress'));
const TransferSuccessful = lazy(() => import('../pages/TransferSuccessful'));
const TransferError = lazy(() => import('../pages/TransferError'));
const TransferDenied = lazy(() => import('../pages/TransferDenied'));
const Widget = lazy(() => import('../pages/Widget'));
const NoAuthorized = lazy(() => import('../pages/NoAuthorized'));
const Page404 = lazy(() => import('../pages/Error404'));
const VoiceSelection = lazy(() => import('../pages/VoiceSelection'));
const Configuration = lazy(() => import('../pages/Configuration'));
const UserInfo = lazy(() => import('../pages/UserInfo'));

export const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path={RoutesName.login} element={<Login />} />
          <Route path="*" element={<Page404 />} />
          <Route element={<ProtectedRoute />}>
            <Route path={RoutesName.userInfo} element={<UserInfo />} />
            <Route path={RoutesName.terms} element={<Terms />} />
            <Route path={RoutesName.selection} element={<MethodSelection />} />
            <Route
              path={RoutesName.voiceSelection}
              element={<VoiceSelection />}
            />
            <Route
              path={RoutesName.configuration}
              element={<Configuration />}
            />

            <Route element={<ProtectedRoute facial vocal />}>
              <Route path={RoutesName.home} element={<Home />} />
              <Route path={RoutesName.transfers} element={<Transfers />} />
              <Route
                path={RoutesName.transfersProgress}
                element={<TransfersProgress />}
              />
              <Route
                path={RoutesName.transferSuccessful}
                element={<TransferSuccessful />}
              />
              <Route
                path={RoutesName.transferError}
                element={<TransferError />}
              />
              <Route
                path={RoutesName.transferDenied}
                element={<TransferDenied />}
              />
            </Route>
            <Route
              path={RoutesName.successfulRegister}
              element={<SuccessfulRegister />}
            />
            <Route
              path={RoutesName.deniedRegister}
              element={<DeniedRegister />}
            />

            <Route path={RoutesName.widget} element={<Widget />} />
            <Route path={RoutesName.noAuthorized} element={<NoAuthorized />} />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};
