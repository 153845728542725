import React, { createContext, useState } from 'react';
import { maxAmount } from '../states/constants';
import { TransferDto } from '../states/model';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  amount: number;
  totalAmount: number;
  name: string;
  handleTransfer(data: TransferDto): void;
  handleTotalAmount(): void;
};

const Ctx = createContext<ContextProps>({
  amount: 0,
  totalAmount: 0,
  name: '',
  handleTransfer: () => {},
  handleTotalAmount: () => {},
});

export const TransferProvider = ({ children }: IProps) => {
  const [name, setName] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(maxAmount);

  const handleTransfer = (data: TransferDto) => {
    setName(data.name);
    setAmount(data.amount);
  };

  const handleTotalAmount = () => {
    setTotalAmount((totalAmount) => totalAmount - amount);
  };

  return (
    <Ctx.Provider
      value={{
        amount,
        name,
        totalAmount,
        handleTransfer,
        handleTotalAmount,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useTransfer = () => React.useContext(Ctx);
