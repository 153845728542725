/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useState, useEffect } from 'react';

const VOID_FUNCTION = () => undefined;

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  userId: string;
  setUser: any;
  sessionId: string;
  setSession: any;
  locationId: string;
  setLocation: any;
  resetGlobalInfo: any;
  showAlert: boolean;
  setShowAlert?: any;
  closeSession: boolean;
  setCloseSession?: any;
};

const Ctx = createContext<ContextProps>({
  userId: '',
  sessionId: '',
  locationId: '',
  setUser: VOID_FUNCTION,
  setSession: VOID_FUNCTION,
  setLocation: VOID_FUNCTION,
  resetGlobalInfo: VOID_FUNCTION,
  showAlert: false,
  setShowAlert: VOID_FUNCTION,
  closeSession: false,
  setCloseSession: VOID_FUNCTION,
});

export const GlobalProvider = ({ children }: IProps) => {
  const [userId, setUserId] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [locationId, setLocationId] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [closeSession, setCloseSession] = useState<boolean>(false);

  const data = window as any;

  useEffect(() => {
    window.addEventListener('closeSession', () => setCloseSession(true));
    window.addEventListener('showAlert', () => setShowAlert(true));

    return () => {
      window.removeEventListener('closeSession', () => setCloseSession(true));
      window.removeEventListener('showAlert', () => setShowAlert(true));
      setShowAlert(false);
      setCloseSession(false);
    };
  }, []);

  const resetGlobalInfo = () => {
    data.userId = '';
    data.sessionId = '';
    data.locationId = '';
    setCloseSession(false);
    setShowAlert(false);
  };

  const setUser = (value: string) => {
    data.userId = value;
    setUserId(value);
  };

  const setSession = (value: string) => {
    data.sessionId = value;
    setSessionId(value);
  };

  const setLocation = (value: string) => {
    data.locationId = value;
    setLocationId(value);
  };

  return (
    <Ctx.Provider
      value={{
        userId,
        sessionId,
        locationId,
        setUser,
        setSession,
        setLocation,
        resetGlobalInfo,
        closeSession,
        setCloseSession,
        showAlert,
        setShowAlert,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useGlobal = () => useContext(Ctx);
