export enum LivenessStatus {
  Spoof = 'SPOOF',
  None = 'NONE',
  Uncertain = 'UNCERTAIN',
  Live = 'LIVE',
  NoneBecauseBadQuality = 'NONE_BECAUSE_BAD_QUALITY',
  NoneBecauseFaceTooClose = 'NONE_BECAUSE_FACE_TOO_CLOSE',
  NoneBecauseFaceNotFound = 'NONE_BECAUSE_FACE_NOT_FOUND',
  NoneBecauseFaceTooSmall = 'NONE_BECAUSE_FACE_TOO_SMALL',
  NoneBecauseAngleTooLarge = 'NONE_BECAUSE_ANGLE_TOO_LARGE',
  NoneBecauseImageDataError = 'NONE_BECAUSE_IMAGE_DATA_ERROR',
  NoneBecauseInternalError = 'NONE_BECAUSE_INTERNAL_ERROR',
  NoneBecauseImagePreprocessError = 'NONE_BECAUSE_IMAGE_PREPROCESS_ERROR',
  NoneBecauseTooManyFaces = 'NONE_BECAUSE_TOO_MANY_FACES',
  NoneBecauseFaceTooCloseToBorder = 'NONE_BECAUSE_FACE_TOO_CLOSE_BORDER',
  NoneBecauseFaceCropped = 'NONE_BECAUSE_FACE_CROPPED',
  NoneBecauseLicenseError = 'NONE_BECAUSE_LICENSE_ERROR',
  NoneBecauseFaceOccluded = 'NONE_BECAUSE_FACE_OCCLUDED',
}

export enum LivenessStatusResponse {
  Spoof = 'Spoof',
  None = 'None',
  Uncertain = 'Uncertain',
  Live = 'Live',
  NoLive = 'NoLive',
  NoneBecauseBadQuality = 'NoneBecauseBadQuality',
  NoneBecauseFaceTooClose = 'NoneBecauseFaceTooClose',
  NoneBecauseFaceNotFound = 'NoneBecauseFaceNotFound',
  NoneBecauseFaceTooSmall = 'NoneBecauseFaceTooSmall',
  NoneBecauseAngleTooLarge = 'NoneBecauseAngleTooLarge',
  NoneBecauseImageDataError = 'NoneBecauseImageDataError',
  NoneBecauseInternalError = 'NoneBecauseInternalError',
  NoneBecauseImagePreprocessError = 'NoneBecauseImagePreprocessError',
  NoneBecauseTooManyFaces = 'NoneBecauseTooManyFaces',
  NoneBecauseFaceTooCloseToBorder = 'NoneBecauseFaceTooCloseToBorder',
  NoneBecauseFaceCropped = 'NoneBecauseFaceCropped',
  NoneBecauseLicenseError = 'NoneBecauseLicenseError',
  NoneBecauseFaceOccluded = 'NoneBecauseFaceOccluded',
}

export type ResponseLiveness = {
  version: string;
  duration: number;
  diagnostic: LivenessStatusResponse;
  trackingMessage: string;
  trackingStatus: number;
};

export type ResponseMatching = {
  version: string;
  duration: number;
  authStatus: FacialAuthenticationStatusResponse;
  similarity: number;
  trackingMessage: string;
  trackingStatus: number;
};

export type ResponseAntispoof = {
  attack: boolean;
};

export enum FacialAuthenticationStatus {
  None = 'NONE',
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
  NoneBecausePoseExceed = 'NONE_BECAUSE_POSE_EXCEED',
  NoneBecauseInvalidExtractions = 'NONE_BECAUSE_INVALID_EXTRACTIONS',
}

export enum FacialAuthenticationStatusResponse {
  None = 'None',
  Negative = 'Negative',
  Positive = 'Positive',
  NoneBecausePoseExceed = 'NoneBecausePoseExceed',
  NoneBecauseInvalidExtractions = 'NoneBecauseInvalidExtractions',
}

export enum OperationResultReason {
  selphiInternalError = 'SELPHI_INTERNAL_ERROR',
  selphiCancelByUser = 'SELPHI_CANCEL_BY_USER',
  selphiTimeout = 'SELPHI_TIMEOUT',
  facialAuthenticationNotPassed = 'FACIAL_AUTHENTICATION_NOT_PASSED',
  facialLivenessNotPassed = 'FACIAL_LIVENESS_NOT_PASSED',
  facialAuthenticationError = 'FACIAL_AUTHENTICATION_ERROR',
  facialLivenessError = 'FACIAL_LIVENESS_ERROR',
}

export enum OperationStatus {
  SUCCEEDED = 'SUCCEEDED',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  DENIED = 'DENIED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
};
