/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState, useContext, useEffect } from 'react';
import { useTracking } from '@facephi/sdk-web';
import { RequestMethods, useRequest, useVariables, useLogger } from '../hooks';
import { imageFace, endPoints, family } from '../states/constants';
import {
  ResponseLiveness,
  ResponseMatching,
  LivenessStatusResponse,
  OperationResultReason,
  FacialAuthenticationStatusResponse,
  ResponseAntispoof,
} from '../states/model';
import { useAuth } from './AuthProvider';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  facialValidated: boolean;
  onValidateFace(image: string): Promise<boolean>;
  onValidateSelphi(image: string): Promise<boolean>;
  onRemoveFacial(): void;
  onSaveFacial(image: string): void;
  onValidateSpoof(
    encryptedLivenessRaw: Blob | File,
    operation?: string,
    session?: string
  ): Promise<boolean>;
  loading: boolean;
  error?: string;
  face?: string | null;
  operationId?: string;
};

const Ctx = createContext<ContextProps>({
  facialValidated: false,
  onRemoveFacial: () => {},
  onSaveFacial: () => {},
  onValidateFace: async () => false,
  onValidateSelphi: async () => false,
  onValidateSpoof: async () => false,
  loading: false,
  face: null,
});

export const FacialProvider = ({ children }: IProps) => {
  const [facialValidated, setFacialValidated] = useState<boolean>(
    localStorage.getItem(imageFace) ? true : false
  );
  const { trackingAuthenticated, operationId, sessionId } = useTracking();
  const { apiDemoUrl } = useVariables();
  const { demoVersion } = useAuth();

  const [face, setFace] = useState<string | null>(
    localStorage.getItem(imageFace)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const { captureException, captureMessage } = useLogger();

  useEffect(() => {
    if (face) {
      setFacialValidated(true);
    }
  }, [face]);

  const { request: validateFacialRequest } = useRequest(apiDemoUrl);

  const onSaveFacial = (image: string) => {
    setFace(image);
    localStorage.setItem(imageFace, image);
  };

  const onRemoveFacial = () => {
    setFace(null);
    setFacialValidated(false);
    localStorage.removeItem(imageFace);
  };

  const onValidateFace = async (image: string): Promise<boolean> => {
    try {
      const responseLiveness = await onValidateLiveness(image);

      if (responseLiveness.diagnostic === LivenessStatusResponse.Live) {
        setError(undefined);
        captureMessage('Liveness Validated');
        return true;
      } else {
        captureMessage('Liveness not validated');
        setError(OperationResultReason.facialLivenessNotPassed);
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      captureException(error as Error, {
        operation: 'validate face',
        operationId,
        sessionId,
      });
      setError(OperationResultReason.facialLivenessError);
      setLoading(false);
      return false;
    }
  };

  const onValidateFacial = async (image: string): Promise<ResponseMatching> => {
    const response: ResponseMatching = await validateFacialRequest(
      endPoints.Authenticate.Matching,
      {
        method: RequestMethods.post,
        headers: {
          'x-inphinite-sessionid': sessionId,
          'x-inphinite-operationid': operationId,
          'x-demo-app-version': demoVersion,
          'x-inphinite-family': family,
        },
        data: {
          image1: face && face.split(',')[1],
          image2: image.split(',')[1],
        },
      }
    );
    captureMessage(`Facial Validation: ${JSON.stringify(response)}`);
    return response;
  };

  const onValidateLiveness = async (
    image: string
  ): Promise<ResponseLiveness> => {
    const response: ResponseLiveness = await validateFacialRequest(
      endPoints.Authenticate.Liveness,
      {
        method: RequestMethods.post,
        headers: {
          'x-inphinite-sessionid': sessionId,
          'x-inphinite-operationid': operationId,
          'x-demo-app-version': demoVersion,
          'x-inphinite-family': family,
        },
        data: {
          image: image.split(',')[1],
        },
      }
    );
    return response;
  };

  const onValidateSelphi = async (image: string): Promise<boolean> => {
    const valueLiveness: boolean = await onValidateFace(image);
    const response: ResponseMatching = await onValidateFacial(image);
    const valueMatching: boolean =
      response.authStatus === FacialAuthenticationStatusResponse.Positive;

    return valueMatching && valueLiveness;
  };

  if (!trackingAuthenticated) {
    return null;
  }

  const onValidateSpoof = async (
    encryptedLivenessRaw: Blob | File
  ): Promise<boolean> => {
    const resultSpoof = await validateFacialRequest(
      endPoints.Authenticate.Antispoofing,
      {
        method: RequestMethods.post,
        headers: {
          'Content-Type': 'application/octet-stream',
          'x-inphinite-sessionid': sessionId,
          'x-inphinite-operationid': operationId,
          'x-demo-app-version': demoVersion,
          'x-inphinite-family': family,
        },
        data: encryptedLivenessRaw,
      }
    );

    return !resultSpoof?.attack;
  };

  return (
    <Ctx.Provider
      value={{
        facialValidated,
        onValidateSelphi,
        onValidateFace,
        onSaveFacial,
        onRemoveFacial,
        onValidateSpoof,
        loading,
        error,
        face,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useFacialAuth = () => useContext(Ctx);
