/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState, useContext, useEffect } from 'react';
import { useTracking } from '@facephi/sdk-web';
import { RequestMethods, useRequest, useVariables, useLogger } from '../hooks';
import { vocalTemplate, endPoints } from '../states/constants';
import {
  ResponseVocalMatching,
  ResponseVocalTemplate,
  VocalAuthenticationStatusResponse,
  VoiceResultReason,
} from '../states/model';
import { useAuth } from './AuthProvider';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  vocalValidated: boolean;
  onCreateVocalTemplate(record: string[]): Promise<string | undefined>;
  onAuthenticateVoice(record: string): Promise<boolean>;
  onRemoveRcord(): void;
  onSaveTemplate(record: string): void;
  loading: boolean;
  error?: string;
  template?: string | null;
  operationId?: string;
};

const Ctx = createContext<ContextProps>({
  vocalValidated: false,
  onRemoveRcord: () => {},
  onSaveTemplate: () => {},
  onCreateVocalTemplate: async () => '',
  onAuthenticateVoice: async () => false,
  loading: false,
  template: null,
});

export const VocalProvider = ({ children }: IProps) => {
  const [vocalValidated, setVocalValidated] = useState<boolean>(
    !!localStorage.getItem(vocalTemplate)
  );

  const { trackingAuthenticated, operationId, sessionId } = useTracking();
  const { apiDemoUrl } = useVariables();
  const { demoVersion } = useAuth();

  const [template, setTemplate] = useState<string | null>(
    localStorage.getItem(vocalTemplate)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const { captureException, captureMessage } = useLogger();

  useEffect(() => {
    if (template) {
      setVocalValidated(true);
    }
  }, [template]);

  const { request: vocalRequest } = useRequest(apiDemoUrl);

  const onSaveTemplate = (record: string) => {
    setTemplate(record);
    localStorage.setItem(vocalTemplate, record);
  };

  const onRemoveRcord = () => {
    setTemplate(null);
    setVocalValidated(false);
    localStorage.removeItem(vocalTemplate);
  };

  const onCreateVocalTemplate = async (records: string[]) => {
    try {
      const responseTemplate: ResponseVocalTemplate = await vocalRequest(
        endPoints.Vocal.Template,
        {
          method: RequestMethods.post,
          headers: {
            'x-demo-app-version': demoVersion,
          },
          data: {
            base64WavFiles: records,
            checkSpoof: false,
            antiSpoofThreshold: 0.5,
          },
        }
      );
      setError(undefined);
      captureMessage('Vocal template created');
      return responseTemplate.template;
    } catch (error) {
      captureException(error as Error, {
        operation: 'create voice template',
        operationId,
        sessionId,
      });
      setError(VoiceResultReason.voiceRegisterError);
      setLoading(false);
    }
  };

  const onValidateRecord = async (
    record: string
  ): Promise<ResponseVocalMatching> => {
    const response: ResponseVocalMatching = await vocalRequest(
      endPoints.Authenticate.MatchingVoice,
      {
        method: RequestMethods.post,
        headers: {
          'x-inphinite-sessionid': sessionId,
          'x-inphinite-operationid': operationId,
          'x-demo-app-version': demoVersion,
        },
        data: {
          base64WavFile: record,
          userVoiceTemplate: template,
          antiSpoofThreshold: 0.5,
        },
      }
    );
    captureMessage(`Record validated: ${JSON.stringify(response)}`);
    return response;
  };

  const onAuthenticateVoice = async (record: string): Promise<boolean> => {
    const response: ResponseVocalMatching = await onValidateRecord(record);
    return response.authStatus === VocalAuthenticationStatusResponse.Positive;
  };

  if (!trackingAuthenticated) {
    return null;
  }

  return (
    <Ctx.Provider
      value={{
        vocalValidated,
        onRemoveRcord,
        onSaveTemplate,
        onCreateVocalTemplate,
        onAuthenticateVoice,
        loading,
        error,
        template,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useVocalAuth = () => useContext(Ctx);
