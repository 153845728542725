import {
  Button,
  FlexContainer,
  setColorTheme,
  CardBase,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles, mobileStyles } from '../../styles';
import { Layout } from '../layout';

export const FormContainer = styled(FlexContainer)``;
export const FormCard = styled(CardBase)`
  width: 95%;

  ${mobileStyles} {
    width: 100%;
  }
`;

export const ContentTransfer = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 2rem;

  > section:first-of-type {
    margin-top: 1.6rem;
  }
`;

export const ButtonContinueTransfer = styled(Button)`
  margin-top: 2.1rem;
`;

export const TransferProgresLayout = styled(Layout)`
  background-image: none;
  background-color: ${setColorTheme('white')};

  p {
    width: 20rem;
  }
`;

export const TransferSuccessfullCard = styled(CardBase)`
  z-index: 20;
  width: 100%;

  ${desktopStyles} {
    width: 35rem;
  }
`;

export const TransferSuccessfullList = styled.ul`
  padding: 1.2rem 1.6rem;

  li:nth-child(1) {
    margin-top: 0.8rem;
  }
  li:nth-child(2) {
    margin-bottom: 1.2rem;
  }
`;
