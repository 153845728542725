import { CSSProperties } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { getCssValue } from '../../styles';

type Props = {
  style?: CSSProperties;
};

export const BarChart = ({ style }: Props) => {
  const { t } = useTranslation();

  const option = {
    title: {
      text: t('Monthly'),
      left: 'left',
      textStyle: {
        fontSize: 16,
      },
    },
    textStyle: {
      fontFamily: 'Poppins',
      color: getCssValue('grey900'),
      fontSize: 16,
    },
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: ['Jun', 'Jul', 'Agost', 'Sept'],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: false,
      },
    ],
    series: [
      {
        name: t('Profitability'),
        type: 'bar',
        barWidth: '70%',
        data: [
          {
            value: 20,
            itemStyle: {
              color: getCssValue('green300'),
            },
          },
          {
            value: 40,
            itemStyle: {
              color: getCssValue('green400'),
            },
          },
          {
            value: 68,
            itemStyle: {
              color: getCssValue('green500'),
            },
          },
          {
            value: 80,
            itemStyle: {
              color: getCssValue('green600'),
            },
          },
        ],
      },
    ],
  };

  return <ReactECharts option={option} style={style} />;
};
