import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { VoiceValidationType } from '../model';

const { persistAtom } = recoilPersist();

export const voiceValidationState = atom<boolean>({
  key: 'voiceVerificationState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const voiceValidationTypeState = atom<VoiceValidationType>({
  key: 'voiceVerificationTypeState',
  default: VoiceValidationType.documentNumber,
  effects_UNSTABLE: [persistAtom],
});

export const voiceValidationCustomState = atom<string>({
  key: 'voiceVerificationCustomState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
