import { EmptyState } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useDeviceSize } from '../../hooks';
import { NoAuthorizedModal } from '../noAuthorized';

export const ErrorFallback = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDeviceSize();

  return (
    <NoAuthorizedModal
      flexDirection="column"
      rowGap={isDesktop ? '2.1' : '1.6'}
      alignItems="center"
      testId="error-boundary-modal"
    >
      <EmptyState
        image={<img src="/error.svg" />}
        title={t('An unexpected error has occurred')}
        subtitle={t('Please try again later') as string}
        style={{ textAlign: 'center' }}
      />
    </NoAuthorizedModal>
  );
};
