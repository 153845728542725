export enum RoutesName {
  login = '/',
  terms = '/terms',
  home = '/home',
  transfers = '/transfers',
  transfersProgress = '/transfersProgress',
  transferSuccessful = '/transferSuccessful',
  transferError = '/transferError',
  transferDenied = '/transferDenied',
  successfulRegister = '/successfulRegister',
  deniedRegister = '/deniedRegister',
  widget = '/widget',
  noAuthorized = '/noAuthorized',
  notFound = '/404',
  selection = '/selection',
  voiceSelection = '/voiceSelection',
  configuration = '/configuration',
  userInfo = '/userInfo',
}

export enum AuthName {
  token = 'TOKEN',
  refreshToken = 'REFRESH_TOKEN',
}

export enum DeviceSize {
  mobile = 768,
  desktop = 1024,
}

export const termsLocalStorage = 'terms';
export const imageFace = 'imageFace';
export const vocalTemplate = 'vocalTemplate';

export const maxAmount = 15000;

export const endPoints = {
  Authenticate: {
    Matching: '/api/authenticate/matching',
    Liveness: '/api/authenticate/liveness',
    MatchingVoice: '/api/authenticate/matching-voice',
    Antispoofing: '/api/authenticate/antispoof',
  },
  Vocal: {
    Template: '/api/phivox/template',
  },
  Versions: '/api/versions',
  Finish: '/api/onboardings/finish',
};

export const family = 'AUTHENTICATION';
