import { GridContainer, Icon, Label, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { ConfigurationLink } from './Styles';

type Props = {
  route: string;
  linkLabel: string;
  title: string;
  onClick?: () => void;
};

export const GoBackHeader = ({ route, title, linkLabel, onClick }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();

  return (
    <GridContainer gridTemplateColumns="1fr 1fr 1fr" alignItems="center">
      <ConfigurationLink
        to={route}
        data-test="go-back-button"
        onClick={onClick}
      >
        <Icon iconName="CaretLeft" size="32" color="blue400" />
        {!isMobile && (
          <Label
            as="span"
            fontSize={isMobile ? '12' : '14'}
            semibold
            color="blue400"
          >
            {t(linkLabel)}
          </Label>
        )}
      </ConfigurationLink>
      <Label
        as="h1"
        fontSize={isMobile ? '16' : '32'}
        semibold
        textAlign="center"
      >
        {t(title)}
      </Label>
    </GridContainer>
  );
};
