import { FlexContainer } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles/utils';
import { Layout } from '../layout';

export const NoAuthorizedLayout = styled(Layout)`
  padding-right: 1rem;
  padding-left: 1rem;

  ${desktopStyles} {
    justify-content: center;
    align-items: center;
  }
`;

export const NoAuthorizedModal = styled(FlexContainer)`
  width: 100%;

  ${desktopStyles} {
    width: 60rem;
    height: 60rem;
    padding: 7rem 9rem;
  }

  button {
    width: 100%;
    margin-top: auto;

    ${desktopStyles} {
      margin-top: 3rem;
      width: auto;
    }
  }

  img {
    margin-bottom: 1.6rem;
    width: 20rem;
    height: 14rem;

    ${desktopStyles} {
      width: 25rem;
      height: 17rem;
    }
  }
`;
