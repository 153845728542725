import { Button } from '@facephi/ui-button';
import { setColorTheme, styled } from '@facephi/ui-theme';
import { animated } from 'react-spring';

export const ModalStyled = styled(animated.div, {
  zIndex: 9999,
  padding: 0,
  background: `${setColorTheme('white')}`,
  borderRadius: '0.8rem',
  display: 'flex',
  flex: '1',
  boxShadow: `0 5px 10px 0 ${setColorTheme('grey200')}`,
  flexDirection: 'column',
  position: 'fixed',
  top: '50%',
  left: '50%',
  minWidth: '34.5rem',
  maxHeight: '100vh',
  overflowY: 'auto',
});

export const SubmitButtonStyled = styled(Button);
