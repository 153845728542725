export enum MimeType {
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
  png = 'image/png',
  mp4 = 'video/mp4',
  pdf = 'application/pdf',
  mp3 = 'audio/mpeg',
}

export enum VocalAuthenticationStatusResponse {
  None = 'None',
  Negative = 'Negative',
  Positive = 'Positive',
  NoneBecausePoseExceed = 'NoneBecausePoseExceed',
  NoneBecauseInvalidExtractions = 'NoneBecauseInvalidExtractions',
}

export type ResponseVocalTemplate = {
  template: string;
};

export type ResponseVocalMatching = {
  authStatus: VocalAuthenticationStatusResponse;
  duration: number;
  similarity: number;
};

export enum VoiceResultReason {
  voiceAuthenticactionError = 'VOICE_AUTHENTICATION_ERROR',
  voiceAuthenticationNotPassed = 'VOICE_AUTHENTICATION_NOT_PASSED',
  voiceCancelByUser = 'VOICE_CANCEL_BY_USER',
  voiceIdCancelByUser = 'VOICE_ID_CANCEL_BY_USER',
  voiceIdInternalError = 'VOICE_ID_INTERNAL_ERROR',
  voiceIdTimeout = 'VOICE_ID_TIMEOUT',
  voiceInternalError = 'VOICE_INTERNAL_ERROR',
  voiceRegisterError = 'VOICE_REGISTER_ERROR',
  voiceTimeout = 'VOICE_TIMEOUT',
}

export const VoiceAssetsOrder = {
  0: 'ONE',
  1: 'TWO',
  2: 'THREE',
  3: 'FOUR',
  4: 'FIVE',
};
