import { Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { BehaviourAlertContent, BehaviourAlertStyles } from './Styles';

type BehaviourAlertProps = {
  show?: boolean;
  onChangeShow(show: boolean): void;
};

export const BehaviourAlert = ({ show, onChangeShow }: BehaviourAlertProps) => {
  const { t } = useTranslation();

  return (
    <BehaviourAlertStyles
      show={show}
      onChangeShow={onChangeShow}
      header={{ noBorder: true }}
      footer={{ noBorder: true }}
      hasPadding
    >
      <BehaviourAlertContent
        flexDirection="column"
        rowGap="3.2"
        alignItems="center"
      >
        <Label fontSize="14" textAlign="center">
          {t(
            'We have detected suspicious behavior and we are going to close your session'
          )}
        </Label>
      </BehaviourAlertContent>
    </BehaviourAlertStyles>
  );
};
