import { Button, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import crossError from '../../assets/lottie/crossError.json';
import pulgarDown from '../../assets/lottie/PulgarDown.json';
import pulgarUp from '../../assets/lottie/PulgarUp.json';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import {
  ValidStatusLottieWrapper,
  ValidStatusStyles,
  ValidStatusTitle,
} from './Styles';

type Props = {
  title: string;
  message?: string;
  onClick?(): void;
  buttonLabel?: string;
  state: 'denied' | 'success' | 'rejected';
};

export const ValidStatus = ({
  title,
  message,
  onClick,
  buttonLabel,
  state,
}: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = useDeviceSize();

  const animationHand =
    state === 'success'
      ? pulgarUp
      : state === 'denied'
      ? pulgarDown
      : crossError;

  return (
    <>
      <ValidStatusStyles
        flexDirection="column"
        forwardedAs="section"
        alignItems="center"
        justifyContent={isDesktop ? 'flex-start' : 'center'}
        flex="1"
      >
        <ValidStatusLottieWrapper animation={animationHand} loop={true} />
        <ValidStatusTitle fontSize="18" semibold textAlign="center">
          {t(`${title}`)}
        </ValidStatusTitle>
        {message && (
          <Label fontSize="16" textAlign="center">
            {t(`${message}`)}
          </Label>
        )}
        {(state === 'denied' || state === 'rejected') && (
          <Button
            color="secondary"
            onClick={onClick}
            size="L"
            testId="try-button"
          >
            {t(`${buttonLabel}`)}
          </Button>
        )}
      </ValidStatusStyles>
    </>
  );
};
