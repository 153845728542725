/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEvent } from 'react';
import { Label, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useFacialAuth, useVocalAuth } from '../../providers';
import {
  facialValidationState,
  voiceValidationState,
  widgetTypeState,
  widgetTypes,
} from '../../states';
import { RoutesName } from '../../states/constants';
import {
  ButtonWrapper,
  IconBox,
  SelectionStyles,
  SelectionButtonsContainer,
} from './Styles';

export const ModalSelection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isDesktop } = useDeviceSize();
  const { facialValidated } = useFacialAuth();
  const { vocalValidated } = useVocalAuth();
  const hasFacialValidation = useRecoilValue(facialValidationState);
  const hasVocalValidation = useRecoilValue(voiceValidationState);

  const setWidgetType = useSetRecoilState(widgetTypeState);
  const transfer = state ? (state as any).transfer : false;

  if (!hasFacialValidation && !hasVocalValidation) {
    return <Navigate to={RoutesName.configuration} replace />;
  }

  const redirectToWidget = (selection: string) => {
    setWidgetType(
      selection === 'face-selection' ? widgetTypes.facial : widgetTypes.vocal
    );
    navigate(RoutesName.widget, {
      state: { ...(state as object), fromSelection: true },
    });
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    const isFacialSelecion = event.currentTarget.id === 'face-selection';
    if (transfer) {
      if (
        (isFacialSelecion && !facialValidated) ||
        (!isFacialSelecion && !vocalValidated)
      ) {
        setWidgetType(
          isFacialSelecion ? widgetTypes.facial : widgetTypes.vocal
        );
      } else {
        redirectToWidget(event.currentTarget.id);
      }
    } else {
      redirectToWidget(event.currentTarget.id);
    }
  };

  return (
    <SelectionStyles
      flexDirection="column"
      rowGap="1.5"
      justifyContent="center"
    >
      <Label fontSize={isDesktop ? '32' : '24'} textAlign="center" semibold>
        {t('How do you prefer to identify yourself?')}
      </Label>
      <SelectionButtonsContainer columnGap="3" justifyContent="center">
        <ButtonWrapper onClick={handleClick} id="face-selection">
          <IconBox
            color="primary"
            size="XL"
            testId="face-selection"
            iconName="UserFocus"
            invertBackground={true}
          />
          <Label fontSize={isDesktop ? '16' : '12'} textAlign="center" semibold>
            {t('Face')}
          </Label>
        </ButtonWrapper>
        <ButtonWrapper onClick={handleClick}>
          <IconBox
            size="XL"
            testId="voice-selection"
            color="primary"
            iconName="Microphone"
            invertBackground={true}
          />
          <Label fontSize={isDesktop ? '16' : '12'} textAlign="center" semibold>
            {t('Voice')}
          </Label>
        </ButtonWrapper>
      </SelectionButtonsContainer>
      <Label fontSize="16" textAlign="center">
        {t('You can add more options from your Settings panel')}
      </Label>
    </SelectionStyles>
  );
};
