import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { widgetTypes } from '../model';

const { persistAtom } = recoilPersist();
export const widgetTypeState = atom<widgetTypes | undefined>({
  key: 'widgetTypeState',
  default: widgetTypes.facial,
  effects_UNSTABLE: [persistAtom],
});
