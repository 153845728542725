import { FlexContainer, Label } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';
import { LottieAnimation } from '../lottieAnimation';

export const ValidStatusStyles = styled(FlexContainer)`
  z-index: 20;
  width: 28rem;

  button {
    width: 100%;
    margin-top: 3.6rem;
  }
`;

export const ValidStatusLottieWrapper = styled(LottieAnimation)`
  border-radius: 100%;
  padding: 2.1rem;
  margin: 1.2rem 0 3.2rem;

  ${desktopStyles} {
    margin: 4rem 0 5.2rem;
  }
`;

export const ValidStatusTitle = styled(Label)`
  margin-bottom: 0.8rem;
`;
