/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StrictMode } from 'react';
import {
  extraErrorDataIntegration,
  debugIntegration,
  httpClientIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sentryDsn: string | undefined = (window as any)._env_
  .REACT_APP_SENTRY_DSN_SELPHI;

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new BrowserTracing(),
      extraErrorDataIntegration(),
      debugIntegration(),
      httpClientIntegration({ failedRequestStatusCodes: [400, 500] }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this in production
    tracesSampleRate: 1.0,
  });
}

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
