/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, useEffect } from 'react';
import {
  useTracking,
  TrackingSteps,
  TrackingStatus,
  AssetsType,
  VoiceWidget,
} from '@facephi/sdk-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { useVariables, useLogger, useFinishOperation } from '../../hooks';
import { useAuth, useVocalAuth, useGlobal } from '../../providers';
import {
  RoutesName,
  VoiceAssetsOrder,
  voiceValidationCustomState,
  VoiceVerifList,
  voiceValidationTypeState,
  VoiceValidationType,
  registrationState,
  userNameState,
  userLastNameState,
  voiceValidationState,
  VoiceResultReason,
  OperationStatus,
} from '../../states';
import { getAssetFromBlob, formatBase64Asset } from '../../utils';

type VoiceWidgetProps = {
  setLoading: Dispatch<boolean>;
  transfer: boolean;
};

export const VoiceRecordWidget = ({
  setLoading,
  transfer,
}: VoiceWidgetProps) => {
  const { i18n, t } = useTranslation();
  const { voiceWidgetLicense } = useVariables();
  const { email, setStartTracking, startTracking } = useAuth();
  const navigate = useNavigate();
  const [registration, setRegistration] = useRecoilState(registrationState);
  const setVocalValidation = useSetRecoilState(voiceValidationState);
  const userName = useRecoilValue(userNameState);
  const userLastName = useRecoilValue(userLastNameState);
  let timeoutId: NodeJS.Timeout;

  const {
    onAuthenticateVoice,
    onCreateVocalTemplate,
    onSaveTemplate,
    vocalValidated,
    error,
  } = useVocalAuth();
  const {
    trackingStart,
    trackingStatus,
    trackingAsset,
    operationId,
    changeOperationId,
    generateOperationId,
  } = useTracking();
  const { setLocation, setSession } = useGlobal();
  const { captureException, captureMessage } = useLogger();
  const { sendFinalOperationStatus } = useFinishOperation();

  useEffect(() => {
    setLocation('voiceWidget');
    if (!vocalValidated) {
      generateOperationId();
    }

    if (startTracking) {
      captureMessage('Voice Operation Started');
      trackingStart(
        [TrackingSteps.start, TrackingSteps.voiceWidget, TrackingSteps.finish],
        email,
        v4()
      );
    }
  }, []);

  useEffect(() => {
    if (operationId) setSession(operationId);
  }, [operationId]);

  useEffect(() => {
    if (error) {
      captureMessage(`Tracking status denied: ${error}`);
      changeOperationId('');
      if (transfer) {
        trackingStatus(
          TrackingStatus.denied,
          error,
          v4(),
          TrackingSteps.voiceWidget
        );
      }
    }
  }, [error]);

  useEffect(() => {
    return () => timeoutId && clearTimeout(timeoutId);
  }, []);

  const sendTrackingAssets = async (assets: string[]) => {
    assets.forEach((asset, index) => {
      trackingAsset(
        asset,
        `${AssetsType.voice}${
          VoiceAssetsOrder[index as keyof typeof VoiceAssetsOrder]
        }` as AssetsType,
        v4(),
        TrackingSteps.voiceWidget
      );
    });
  };

  const vocalValidationType = useRecoilValue(voiceValidationTypeState);
  const vocalCustomSentence = useRecoilValue(voiceValidationCustomState);

  const addSentence = (sentence: string): string[] => {
    const limit = registration ? 3 : 1;
    const vocalSentences: string[] = [];
    for (let i = 0; i < limit; i++) {
      vocalSentences.push(sentence);
    }
    return vocalSentences;
  };

  const getSentences = (): string[] => {
    if (vocalValidationType === VoiceValidationType.custom) {
      return addSentence(vocalCustomSentence);
    } else if (vocalValidationType === VoiceValidationType.nameLastname) {
      return addSentence(`${userName} ${userLastName}`);
    } else {
      return addSentence(t(VoiceVerifList[vocalValidationType].title));
    }
  };

  const onRecordingFinish = async (event: CustomEvent<Blob[]>) => {
    setLoading(true);
    try {
      const base64Records = await getAssetFromBlob(event.detail);
      const formattedAsset = formatBase64Asset(base64Records);

      if ((transfer && vocalValidated) || !registration) {
        // Vocal matching
        sendTrackingAssets(base64Records);

        const response = await onAuthenticateVoice(formattedAsset[0]);
        if (response) {
          timeoutId = setTimeout(() => {
            trackingStatus(
              TrackingStatus.succeeded,
              '',
              v4(),
              TrackingSteps.voiceWidget
            );
            sendFinalOperationStatus(OperationStatus.SUCCEEDED, null);
          }, 3000);
          captureMessage('Voice matching succesful');
          navigate(RoutesName.transferSuccessful);
        } else {
          sendFinalOperationStatus(
            OperationStatus.DENIED,
            VoiceResultReason.voiceAuthenticationNotPassed
          );
          captureMessage('Voice matching denied');
          navigate(RoutesName.transferDenied);
        }
        changeOperationId('');
      } else {
        if (formattedAsset.length) {
          // Vocal registration
          const template = await onCreateVocalTemplate(formattedAsset);
          if (template) {
            onSaveTemplate(template);
            setRegistration(false);
            setVocalValidation(true);

            captureMessage('Voice registration succesful');
            navigate(RoutesName.successfulRegister);
          } else {
            captureMessage('Voice registration denied');
            navigate(RoutesName.deniedRegister);
          }
          changeOperationId('');
        }
      }
    } catch (e: any) {
      if (transfer) {
        sendFinalOperationStatus(
          OperationStatus.DENIED,
          VoiceResultReason.voiceInternalError
        );
      }
      captureException(e as Error);
      navigate(RoutesName.deniedRegister);
    }
  };

  const onExceptionError = async () => {
    captureMessage('Denied Register Exception');
    changeOperationId('');
    if (transfer) {
      trackingStatus(
        TrackingStatus.denied,
        'Denied Register Exception',
        v4(),
        TrackingSteps.voiceWidget
      );
      sendFinalOperationStatus(
        OperationStatus.DENIED,
        VoiceResultReason.voiceRegisterError
      );
    }
    setStartTracking(true);
    navigate(RoutesName.deniedRegister);
  };

  const onUserCancel = async () => {
    captureMessage('Denied register: User cancel');
    if (registration) setVocalValidation(false);
    changeOperationId('');
    if (transfer) {
      trackingStatus(
        TrackingStatus.cancelled,
        VoiceResultReason.voiceIdCancelByUser,
        v4(),
        TrackingSteps.voiceWidget
      );
      sendFinalOperationStatus(
        OperationStatus.CANCELLED,
        VoiceResultReason.voiceCancelByUser
      );
    }
    setStartTracking(true);
    navigate(RoutesName.deniedRegister);
  };

  return (
    <VoiceWidget
      className="sdk-widget voice-widget"
      bundlePath={`${process.env.PUBLIC_URL}voice`}
      language={i18n.language.split('-')[0]}
      license={voiceWidgetLicense}
      sentences={getSentences()}
      onUserCancel={onUserCancel}
      onRecordingFinish={onRecordingFinish}
      onExceptionError={onExceptionError}
      showUserCancel={true}
    />
  );
};
