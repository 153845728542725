import { Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import faceTip from '../../assets/lottie/faceTip.json';
import voiceTipDesktop from '../../assets/lottie/voiceTipDesktop.json';
import voiceTipMobile from '../../assets/lottie/voiceTipMobile.json';
import { useDeviceSize } from '../../hooks';
import { widgetTypeState, widgetTypes } from '../../states';
import { LottieAnimation } from '../lottieAnimation';
import { ContainerHelp, ContainerHelpButton } from './Styles';

type IProps = {
  onClick(): void;
};

export const HelpWidget = ({ onClick }: IProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useDeviceSize();
  const widgetType = useRecoilValue(widgetTypeState);
  const isFacial = widgetType === widgetTypes.facial;
  const DESKTOP_SIZE = '32rem';
  const MOBILE_SIZE = '24rem';
  const lottieStyles = isDesktop
    ? { height: DESKTOP_SIZE, width: DESKTOP_SIZE }
    : { height: MOBILE_SIZE, width: MOBILE_SIZE };
  const voiceTip = isDesktop ? voiceTipDesktop : voiceTipMobile;

  return (
    <ContainerHelp
      flexDirection="column"
      flex="1"
      alignItems="center"
      rowGap={isDesktop ? '3.2' : '2.4'}
    >
      <Label
        fontSize={isDesktop ? '32' : '24'}
        textAlign="center"
        semibold
        noWrap
      >
        {t(isFacial ? 'Facial recognition' : 'Voice recognition')}
      </Label>
      <LottieAnimation
        animation={isFacial ? faceTip : voiceTip}
        style={{ padding: '2rem', ...lottieStyles }}
      />

      <Label fontSize="18" textAlign="center">
        {t(
          isFacial
            ? 'Place your face inside the circle'
            : 'Speak clearly and loudly. Make sure you are in a quiet environment.'
        )}
      </Label>

      <ContainerHelpButton
        color="secondary"
        onClick={onClick}
        size="L"
        testId="try-button"
      >
        {t('Start').toUpperCase()}
      </ContainerHelpButton>
    </ContainerHelp>
  );
};
