import { MouseEventHandler } from 'react';
import {
  Icon,
  IconName,
  InphiniteThemeColors,
  IconSize,
} from '@facephi/ui-react';
import { GoBackContainer, GoBackLabel } from './Styles';

export const GoBackButton = ({
  iconColor,
  iconSize,
  iconName,
  labelText,
  onClick,
  testId,
}: {
  iconSize?: IconSize;
  iconColor?: InphiniteThemeColors;
  iconName: IconName;
  labelText: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  testId?: string;
}) => (
  <GoBackContainer
    onClick={onClick}
    justifyContent="center"
    alignItems="center"
    testId={testId}
  >
    <Icon iconName={iconName} color={iconColor} size={iconSize} />
    <GoBackLabel color="primary400" size={14}>
      {labelText}
    </GoBackLabel>
  </GoBackContainer>
);
