import { setColorTheme, InphiniteThemeColors } from '@facephi/ui-react';
import { DeviceSize } from '../states/constants';

export const desktopStyles = () => {
  return `@media (min-width: ${DeviceSize.desktop}px)`;
};

export const mobileStyles = () => {
  return `@media (max-width: ${DeviceSize.mobile}px)`;
};

export const HEADER_HEIGHT = '6.4rem';

export const getCssValue = (cssVariable: InphiniteThemeColors) => {
  const rawValue = setColorTheme(cssVariable).replace(/var\(|\)/gm, '');
  return getComputedStyle(document.body).getPropertyValue(rawValue);
};
