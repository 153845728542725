import { ChangeEvent, Dispatch, useState, useEffect } from 'react';
import { RadioGroup, RadioButton } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  VoiceValidationType,
  userLastNameState,
  userNameState,
  voiceValidationCustomState,
  voiceValidationTypeState,
  voiceValidationState,
} from '../../states';
import { CustomSentenceAdvice, CustomSentenceInput } from './Styles';

type VoiceTypesSelectionProps = {
  setDisableButton?: Dispatch<boolean>;
};

export const VoiceTypesSelection = ({
  setDisableButton,
}: VoiceTypesSelectionProps) => {
  const { t } = useTranslation();

  const userName = useRecoilValue(userNameState);
  const userLastName = useRecoilValue(userLastNameState);
  const hasVoiceVelidation = useRecoilValue(voiceValidationState);

  const [voiceVerifType, setVoiceVerifType] = useRecoilState(
    voiceValidationTypeState
  );
  const [voiceVerifCustom, setVoiceVerifCustom] = useRecoilState(
    voiceValidationCustomState
  );
  const [inputError, setInputError] = useState<boolean>(false);

  useEffect(() => {
    setDisableButton &&
      setDisableButton(
        (voiceVerifType === VoiceValidationType.custom &&
          (!voiceVerifCustom || inputError)) ||
          !voiceVerifType
      );
  }, [voiceVerifType, voiceVerifCustom, inputError]);

  const checkErrorInput = (sentence: string): boolean => {
    const regex = new RegExp(/[a-zA-Z]+\s+[a-zA-Z]+/g);
    return !regex.test(sentence);
  };
  const changeSelectionHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const selection = e.target.value as VoiceValidationType;
    if (selection !== VoiceValidationType.custom) {
      setInputError(false);
      setVoiceVerifCustom('');
    }
    setVoiceVerifType(
      VoiceValidationType[e.target.value as VoiceValidationType]
    );
  };

  const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setVoiceVerifCustom(e.target.value);
    setTimeout(() => setInputError(checkErrorInput(e.target.value)), 250);
  };

  const disableInput = voiceVerifType !== VoiceValidationType.custom;

  const voiceValidationList: {
    id: VoiceValidationType;
    title: string;
    active: boolean;
  }[] = [
    {
      id: VoiceValidationType.nameLastname,
      title: `"${userName} ${userLastName}"`,
      active: !!userName || !!userLastName,
    },
    {
      id: VoiceValidationType.documentNumber,
      title: t('Your id card number'),
      active: true,
    },
    {
      id: VoiceValidationType.facephiBank,
      title: `"${t('My bank is Facephi')}"`,
      active: true,
    },
    {
      id: VoiceValidationType.custom,
      title: t('Enter a custom phrase'),
      active: true,
    },
  ];

  return (
    <>
      <RadioGroup
        name="voice-message"
        defaultSelected={voiceVerifType}
        onChange={changeSelectionHandler}
        testId="voice-message"
        rowGap="2"
      >
        {voiceValidationList
          .filter(({ active }) => active)
          .map(({ id, title }) => (
            <RadioButton
              key={id}
              label={t(title)}
              value={id}
              disabled={!hasVoiceVelidation}
            />
          ))}
      </RadioGroup>
      <CustomSentenceInput
        placeholder={String(t('Custom phrase'))}
        disabled={disableInput}
        onChange={changeInputHandler}
        error={inputError}
        value={voiceVerifCustom}
      >
        <CustomSentenceAdvice
          fontSize="12"
          color={disableInput ? 'grey300' : inputError ? 'error400' : 'grey700'}
        >
          {t('You must enter at least two words')}
        </CustomSentenceAdvice>
      </CustomSentenceInput>
    </>
  );
};
